<template>
  <div class="main-page">
    <div class="container">
      <banner v-on:modal-sub-info="this.$root.showModalSubIdInfo($event)" :content="banners"/>
      <div class="new-products-wrap">
        <div class="title-lined">
          <div class="title">{{ header(language) }}</div>
        </div>
        <div class="cf-integrated-communication-content"></div>
        <div class="subscription-wrapper" :class="[loadedClass]">
          <!--<div v-for="(subRow, rowIndex) in row(subscriptions, 4)" v-bind:class="rowStyle(subRow.length, rowIndex)">
            <subscription-component
                v-for="(sub, subIndex) in subRow" :sub="sub" :style="style(sub, rowIndex, subRow.length, subIndex)" :hasResult="hasResult(sub)"
                v-on:modal-sub-info="this.$root.showModalSubInfo($event)" v-on:modal-sub-process="showModalSubProcess($event)"
            />
          </div>-->
          <div v-for="(subRow, rowIndex) in row(subscriptions, 0/*4*/)" v-bind:class="rowStyle(subRow.length, rowIndex)">
            <subscription-component
                v-for="(sub, subIndex) in subRow"
                :sub="sub"
                :style="style(sub, rowIndex, subRow.length, subIndex)"
                :hasResult="hasResult(sub)"
                v-on:modal-sub-info="this.$root.showModalSubInfo($event)"
                v-on:modal-sub-process="showModalSubProcess($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <teleport to="#modals">
      <subscription-process-modal :sub="modalSubProcess" @close="modalSubProcess = null"></subscription-process-modal>
    </teleport>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {useDataService} from '../store/DataService';
import Banner from "../components/banner.vue";
import SubscriptionComponent from "../components/subscription.vue";
import SubscriptionDetailsModal from "../components/subscription-details-modal";
import SubscriptionProcessModal from "../components/subscription-process-modal";
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData";
import {Catalog} from "../entity/Content";
import * as UI from "../ui";

export default defineComponent({
  name: 'Section',
  props: ['catalogs', 'language', 'quizWithResult'],
  components: {
    Banner,
    SubscriptionComponent,
    SubscriptionDetailsModal,
    SubscriptionProcessModal
  },
  methods: {
    style(value: SubItem, rowIndex: number, itemsInRow: number, subIndex: number) : Object {
      return UI.style(value.id, rowIndex, itemsInRow, subIndex)
    },
    rowStyle(itemsInRow: number, rowIndex: number) {
      return UI.rowStyle(itemsInRow, rowIndex)
    },
    row<T>(arr: Array<T>, size: number = 10): Array<Array<T>> {
      return UI.row(arr, size)
    },
    header(lang: Language): string {
      return (this.catalogs as Catalog[])
          .filter((cat: Catalog): boolean => cat.id === this.catalogId)
          .map((cat: Catalog): string => {
            switch (lang) {
              case Language.RU:
                return cat.labelRu;
              case Language.TJ:
                return cat.labelTj;
              default:
                return cat.labelRu;
            }
          })
          .find((__) => true);
    },
    hasResult(sub: SubItem): Boolean {
      return !!sub ? this.quizWithResult.indexOf(sub.id) >= 0 : false;
    },
    loadData(catalogId: string, routeName: string) {
      this.loading = true;
      this.catalogId = catalogId; // this.$route.meta.header or $route.matched[0].props.default.header
      this.loadedClass = '';
      this.dataService.catalogContent(routeName)
          .then(response => this.subscriptions = response)
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      this.dataService.banners(routeName)
          .then(response => this.banners = response)
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.loadedClass = 'loading-done';
          });
    },
    showModalSubProcess(sub: SubItem) {
      this.modalSubProcess = sub;
    },
  },
  data() {
    return {
      catalogId: null,
      modalSubProcess: null,
      dataService: [],
      subscriptions: [],
      banners: [],
      loadedClass: '',
      loading: true,
      errored: false
    };
  },
  watch: {
    $route(to, from) {
      this.loadData(this.$route.meta.catalogId, this.$route.name)
    }
  },
  mounted() {
    this.dataService = useDataService();
    this.loadData(this.$route.meta.catalogId, this.$route.name)
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
@import "../components/subscription";

@media (min-width: $screen-sm) {
 //@include section-align-2-to-2()
  @include section-align-3col();
}

@media (max-width: $screen-sm) {
  // disable banner on mobile
  .main-banner .swiper {
    display: none;
  }

  @include section-align-vertical();
}

.subscription-wrapper {
  @include subscription-wrapper-impl();
}

</style>
