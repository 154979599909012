<template>
  <transition name="modal">

    <div class="modal-mask" v-show="!!newsArticle" ref="modal" tabindex="0" @keydown.esc="doClose">
      <div class="modal-wrapper modal-sub-info">
        <div class="modal-container">

            <div class="modal-header">
              <slot name="header">
                <div class="modal-title">{{attr(nf.TITLE, this.language)}}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="doClose">
                  <span class="icon" aria-hidden="true">&times;</span>
                  <span class="text">{{ $t('message.close') }}</span>
                </button>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <div class="description" v-html="attr(nf.FULL_DESC, this.language)"></div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <!--<button class="modal-default-button" @click="$emit('close')">OK</button>-->
                <mf-button @click.stop="doClose" :text="`OK`" :style="`solid-button`"></mf-button>
              </slot>
            </div>
          </div>
        </div>
      </div>
  </transition>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData";
import SubscriptionAction from "./subscription-action";
import MfButton from "./mf-button";
import {NewsFact} from "../entity/News";

export default defineComponent({
  name: 'NewsArticleModalComponent',
  props: ['newsArticle', 'language'],
  components: {
    SubscriptionAction,
    MfButton
  },
  data() {
    // console.info(this.language);
    return {
      // lang: this.language,
      shadowNewsArticle: null, // prevent thin modal during modal closing
      nf: NewsFact,
    }
  },
  watch: {
    newsArticle: function(newVal, oldVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal != null) {
        this.shadowNewsArticle = newVal;
        this.show();
      }
    },
    /*'$i18n.locale': function (locale) {
      this.lang = Language[locale.toUpperCase()];
    }*/
  },
  methods: {
    subId(): string {
      return !this.sub ? null : this.sub.id;
    },
    attr(fact: SubFact, lang: Language): string {
      // console.info(`${fact} - ${JSON.stringify(lang)}`);
      const locAttr = this.shadowNewsArticle?.facts?.[fact]?.[lang];
      if (locAttr?.length > 0) {
        return locAttr;
      } else {
        return this.shadowNewsArticle?.facts?.[fact]?.[Language.RU]
      }
    },
    show() {
      // When the modal is shown, we want a fixed body
      const top = `-${window.scrollY}px`
      document.body.style.position = 'fixed';
      document.body.style.top = top;

      setTimeout(() => {
        this.$refs.modal.focus();
      }, 5)
    },
    doClose() {
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);

      this.$emit('close');
    },
  },
  created() {
    /*document.addEventListener('keydown', (e) => {
      if (!!this.sub && e.keyCode == 27) {
        this.close()
      }
    })*/
    // this.$nextTick().then(() => this.$root.$emit("modalShow"));
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
@import "./subscription-details";
.solid-button {
  @include button($green, $white, 50px);
}
</style>