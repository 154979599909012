
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'MfButton',
  props: ['text', 'style'],
  // bind(el, binding, vnode) {
  //   el.appendChild(binding.value);
  // },
  methods: {
    rippleAction(e): void {

      // create .ink element if it doesn't exist
      /*if (this.$el.querySelector(".ink").length === 0) {
        const ink = document.createElement("span");
        ink.classList.add("ink");
        this.$el.appendChild(ink);
      }*/

      const rippler = this.$el;
      const ink = this.$el.querySelector(".ink");

      // prevent quick double clicks
      ink.classList.remove("animate");

      // set .ink diametr
      if (!ink.style.height && !ink.style.width) {
        const ripplerStyle: CSSStyleDeclaration = window.getComputedStyle(rippler);
        const ripplerAbsoluteWidth: number = Math.ceil(rippler.offsetWidth + parseFloat(ripplerStyle['marginLeft']) + parseFloat(ripplerStyle['marginRight']));
        const ripplerAbsoluteHeight: number = Math.ceil(rippler.offsetWidth + parseFloat(ripplerStyle['marginTop']) + parseFloat(ripplerStyle['marginBottom']));
        const d: number = Math.max(ripplerAbsoluteWidth, ripplerAbsoluteHeight);
        ink.style.height = `${d}px`;
        ink.style.width = `${d}px`;
      }

      // get click coordinates
      const inkStyle = window.getComputedStyle(ink);
      const inkAbsoluteWidth: number = Math.ceil(ink.offsetWidth + parseFloat(inkStyle['marginLeft']) + parseFloat(inkStyle['marginRight']));
      const inkAbsoluteHeight: number = Math.ceil(ink.offsetWidth + parseFloat(inkStyle['marginTop']) + parseFloat(inkStyle['marginBottom']));
      const x: number = e.pageX - rippler.getBoundingClientRect().left - window.scrollX - inkAbsoluteWidth / 2;
      const y: number = e.pageY - rippler.getBoundingClientRect().top - window.scrollY - inkAbsoluteHeight / 2;

      // set .ink position and add class .animate
      ink.style.top = `${y}px`;
      ink.style.left = `${x}px`;
      ink.classList.add("animate");
    }
  }
});
