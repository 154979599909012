<template>
  <div class="news-list">
    <div class="news-block" v-for="(item, index) in newsContent" :class="`style-${item.subscriptionId}`">
      <!--<div class="icon-block"><div class="icon icon-sim-card"></div></div>-->
        <div class="icon colorized"></div>
        <div class="top-block">
          <div class="title">{{ attr(item, nf.TITLE, this.language) }}</div>
          <div class="text">{{ attr(item, nf.SHORT_DESC, this.language) }}</div>
        </div>
        <a href="#" class="read-more" @click.prevent="showModal(item)">{{ $t('message.continueReading') }}</a>
    </div>
  </div>
  <a href="#" class="load-more" @click.prevent.stop="loadMore()" v-if="page?.last === false">{{ $t('message.load_more') }}</a>
  <nav aria-label="navigation" v-if="page?.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" :class="[page.number === 0 ? 'disabled' : '']">
        <a class="page-link" v-if="page.first === false" @click.prevent.stop="toPage(page.number - 1)" href="#"><span class="icon icon-chevron-thin-left"></span></a>
        <a class="page-link" v-if="page.first === true" @click.prevent.stop="toPage(page.number)"><span class="icon icon-chevron-thin-left"></span></a>
      </li>

      <li class="page-item" v-for="(pageNo, index) in sequence()" :class="[page.number === pageNo ? 'active' : '']">
        <a class="page-link" v-if="page.number === pageNo" @click.prevent.stop="toPage(page.number)">
          <span>{{ (pageNo + 1) }}</span>
        </a>
        <a class="page-link" v-if="page.number !== pageNo" @click.prevent.stop="toPage(pageNo)" href="#">
          <span>{{ (pageNo + 1) }}</span>
        </a>

      </li>
      <li class="page-item" :class="[page.number + 1 >= page.totalPages ? 'disabled' : '']">
        <a class="page-link" v-if="page.last === false" @click.prevent.stop="toPage(page.number + 1)" href="#"><span class="icon icon-chevron-thin-right"></span></a>
        <a class="page-link" v-if="page.last === true" @click.prevent.stop="toPage(page.number)"><span class="icon icon-chevron-thin-right"></span></a>
      </li>
    </ul>
  </nav>
  <teleport to="#modals">
    <news-article-modal-component :news-article="modalNewsArticle" :language="language" v-on:close="modalNewsArticle = null"></news-article-modal-component>
  </teleport>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {Novelty} from "../entity/Content";
import {News, NewsFact} from "../entity/News";
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";
import NewsArticleModalComponent from "./news-article-modal.vue"
import {useDataService} from "../store/DataService";
import {PageImpl, pages} from "../entity/spring/Pagination";
export default defineComponent( {
  name: 'MainNews',
  components: {
    NewsArticleModalComponent
  },
  props: ['content', 'language'],
  watch: {
    content: function(newVal, oldVal) {
      this.page = (!!newVal) ? newVal : [];
      this.newsContent.push(...this.page?.content);
    },
   /* '$i18n.locale': function (locale) {
      this.lang = Language[locale.toUpperCase()];
    }*/
  },
  data() {
    return {
      // lang: Language,
      nf: NewsFact,
      page: null as PageImpl<News>,
      newsContent: [],
      dataService: [],
      modalNewsArticle: null
    }
  },
  methods: {
    attr(news: News, fact: NewsFact, lang: Language): string {
      const locAttr = news?.facts?.[fact]?.[lang];
      if (locAttr?.length > 0) {
        return locAttr;
      } else {
        return news?.facts?.[fact]?.[Language.RU]
      }
    },
    toPage(newPageNumber: number) {
      if (newPageNumber === this.page.number) {
        // skip
      } else {
        this.dataService.news(newPageNumber).then((newPage) => {
          this.page = newPage;
          this.newsContent = newPage.content;
        })
      }
    },
    loadMore() {
      if (!this.page.last) {
        this.dataService.news(this.page.number + 1).then((newPage) => {
          this.page = newPage;
          const content = this.page?.content
          this.newsContent.push(...content);
        })
      }
    },
    sequence() {
      return pages(this.page.number, this.page.totalPages, 5);
    },
    showModal(newsArticle: News) {
      this.modalNewsArticle = newsArticle;
      // this.$emit('modal-sub-info', subId);
    }
  },
  mounted() {
    this.dataService = useDataService();
  }
})
</script>
<style lang="scss">
@import "../../scss/mixins";

nav {
  //display: block;
  display: none;
  margin: 0 auto;
}

.load-more {
  display: block;
  width: 100%;

  grid-row-start: auto;
  grid-column: 1 / 4;

  color: $black;
  text-align: center;
  padding: 28px;

  &:hover {
    color: $black;
  }
}

.news-list {

  .news-block {
    position: relative;

    .icon {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      top: 20px;
      left: 20px;
      position: absolute;

      background-position: center;
      background-size: 60%;
      background-repeat: no-repeat;

      border-radius: 50%;
    }

    .top-block,
    .read-more {
      margin-left: 75px;
    }
  }

  @media (min-width: $screen-sm) {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    /*.text {
      display: none;
    }*/

    /*.news-block {
      &:nth-child(5n+2) {
        .text {
          display: block;
        }
        grid-row-end: span 2;
      }
    }*/
  }

}

</style>