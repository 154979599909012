import {inject, provide, reactive} from 'vue';
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";

function openInNewTab(href: String) : void {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        href: href,
    }).click();
}

function assistantUrl(subItem : SubItem): string {
    return "https://live.megafon.tj/"
}

export const subscriptionServiceSymbol = Symbol('subscriptionService');
export const createSubscriptionService = () => reactive({
    doSubscribe: (subscriptionId: string): void => {
        console.info(`doRequestRegulations: ${subscriptionId}`);
        // alert(`doSubscribe: ${subscriptionId}`);
    },
    doRequestAssistance: (subItem : SubItem): void => {
        // console.info(`doRequestAssistance: ${JSON.stringify(subItem)}`);
        const url = assistantUrl(subItem);
        // window.location.href = url
        openInNewTab(url)
    },
    doRequestRegulations: (subItem : SubItem): void => {
        // console.info(`doRequestRegulations: ${JSON.stringify(subItem)}`);
        let regulationUrl = SubItem.fact(subItem, SubFact.REGULATION, Language.RU) // todo 
        // window.location.href = regulationUrl
        // alert(`doRequestRegulations: ${subItem.facts}`);
        if (!!regulationUrl) {
            openInNewTab(regulationUrl)
        } else {
            console.warn(`no url for subItem ${JSON.stringify(subItem)}`);
        }
    },
});
export const useSubscriptionService = () => inject(subscriptionServiceSymbol);
export const provideDataService = () => provide(
    subscriptionServiceSymbol,
    createSubscriptionService()
);