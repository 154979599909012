import {News} from "./News";
import {SubItem} from "./SubData";
import {Language} from "./Core";
import {PageImpl} from "./spring/Pagination";

export class Catalog {
    constructor(
        public readonly id: string,
        public readonly parentId: string,
        public readonly priority: number,
        public readonly visible: boolean,
        public readonly labelRu: string,
        public readonly labelTj: string
    ) {
    }
}

export class Banner {
    constructor(
        public readonly subId:string,
        public readonly desktopImgPath:string,
        public readonly mobileImgPath:string,
        public readonly priority:number
    ) {
    }
}

export class Novelty {
    constructor(
        public readonly target: SubItem,
        public readonly imgUrl: string
    ) {
    }
}

export class CorePack {
    constructor(
        public readonly msisdn: string,
        public readonly catalogs: Array<Catalog>,
        public readonly quizWithResult: Array<String>,
    ) {
    }
}

export class HomePack {
    constructor(
        public readonly banners: Array<Banner>,
        public readonly novelties: Array<Novelty>,
        public readonly news: PageImpl<News>,
    ) {
    }
}