
import {defineComponent} from 'vue';
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData"
import {useSubscriptionService} from '../store/SubsctiptionService';
import SubscriptionAction from "./subscription-action.vue";
import MfButton from "./mf-button.vue";

export default defineComponent({
  name: 'SubscriptionComponent',
  props: {
    'sub': Object, // SubItem,
    'style': Object, // string: boolean
    'hasResult': Boolean
  },
  components: {
    MfButton,
    SubscriptionAction
  },
  data() {
    return {
      subscriptionService: null,
      f: SubFact,
      openState: "",
      descriptionOpen: false,
      priceOpen: false,
      termsOpen: false,
      infoOpen: false
    }
  },
  methods: {
    attr(fact: SubFact): string {
      const si: SubItem = this.sub;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      return si?.facts?.[fact]?.[lang];
    },
    showModalSubInfo() {
      // console.info(this.sub); // proxy instead of object
      this.$emit('modal-sub-info', this.sub);
    },
    showModalSubProcess() {
      this.$emit('modal-sub-process', this.sub);
    },
    toggleDescription() {
      this.descriptionOpen = !this.descriptionOpen;
      this.openState = this.descriptionOpen ? 'descriptionOpen' : '';
      this.priceOpen = false;
      this.termsOpen = false;
      this.infoOpen = false;
    },
    togglePrice() {
      this.priceOpen = !this.priceOpen;
      this.openState = this.priceOpen ? 'priceOpen' : '';
      this.descriptionOpen = false;
      this.termsOpen = false;
      this.infoOpen = false;
    },
    toggleTerms() {
      this.termsOpen = !this.termsOpen;
      this.openState = this.termsOpen ? 'termsOpen' : '';
      this.descriptionOpen = false;
      this.priceOpen = false;
      this.infoOpen = false;
    },
    toggleInfo() {
      this.infoOpen = !this.infoOpen;
      this.openState = this.infoOpen ? 'infoOpen' : '';
      this.descriptionOpen = false;
      this.priceOpen = false;
      this.termsOpen = false;
    },
  },
  mounted() {
    this.subscriptionService = useSubscriptionService();
  }
})
