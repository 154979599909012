<template>
    <div class="categories-list">
      <router-link :class="`${cat.id}`" v-for="cat in catalogs" :to="`/${cat.id}`" class="category-block">
        <div class="category-inner">
          <div class="title">{{label(cat, language)}}</div>
          <div class="icon-block">
            <div class="inner-block"><div class="icon" :class="[`icon-${cat.id}`]"></div></div>
          </div>
        </div>
      </router-link>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {Language} from "../entity/Core";
import {Catalog} from "../entity/Content";
export default defineComponent( {
  name: 'MainCategories',
  props: ['catalogs', 'language'],
  watch: {
    '$i18n.locale': function (locale) {
      this.lang = Language[locale.toUpperCase()];
    }
  },
  data() {
    return {
    }
  },
  methods: {
    label(cat: Catalog, lang: Language): string {
      switch (lang) {
        case Language.RU:
          return cat.labelRu;
        case Language.TJ:
          return cat.labelTj;
        default:
          return cat.labelRu;
      }
    }
  },
})
</script>
<style lang="scss">
@import "../../scss/variables";
/*.main-page .categories-list .category-block {
  background-color: $white;
  .title {
    color: $green;
  }
  .icon-block {
    background-color: $green;
    .icon {
      color: $white;
    }
  }
}*/

.main-page .categories-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .category-block {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .main-page .categories-list .category-block .icon-block .icon {
    &.icon-education:before {
      font-size: 18px;
    }
  }
}
</style>