
import {defineComponent} from 'vue'
import {useDataService} from "../store/DataService";
import {useSubscriptionService} from '../store/SubsctiptionService';
import {Language} from "../entity/Core";
import {ResponseCode, SubFact, SubItem} from "../entity/SubData";
import MfButton from "./mf-button.vue";
import {AxiosError} from "axios/index.d.ts";
export default defineComponent({
  name: 'SubscriptionProcess',
  props: {
    'sub': Object, // SubItem,
  },
  components: {
    MfButton
  },
  data() {
    return {
      subscriptionService: null,
      loading: true,
      msisdn: null,
      errorMessage: false,
      inputMsisdn: "",
      inputCode: "",
      subscribeComplete: false,
      f: SubFact
    }
  },
  methods: {
    subId(): string {
      return !this.sub ? null : this.sub.id;
    },
    attr(fact: SubFact): string {
      const si: SubItem = this.sub;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      // return !si ? null : si.facts[fact][Language.RU];
      return si?.facts?.[fact]?.[lang];
    },
    doSendCode(emmitError: boolean = true) {
      if (!!this.inputMsisdn && this.inputMsisdn.length >= 1 ) {
        console.info(this.inputMsisdn);
        console.info(this.subId());
        this.dataService.sendCode(this.inputMsisdn, this.subId())
            .then(response => {
              const result: ResponseCode = response;

              console.info("result doSendCode");
              console.info(result);

              // todo process
              switch (result) {
                case ResponseCode.SUCCESS_SEND:
                  this.msisdn = this.inputMsisdn;
                  break;
                case ResponseCode.SUCCESS_SUB:
                  this.subscribeComplete = true;
                  break;
                case ResponseCode.UNSUCCESS_SUB:
                  this.errorMessage = "Ошибка при подписке";
                  break;
                case ResponseCode.NO_MONEY:
                  this.errorMessage = "Недостаточно средств";
                  break;
                case ResponseCode.BAD_CODE:
                  this.errorMessage = "Некорректный код";
                  break;
                case ResponseCode.ALREADY_SENT:
                  this.errorMessage = "Код уже отправлен";
                  break;
                case ResponseCode.RETRY_SEND:
                  this.msisdn = this.inputMsisdn;
                  break;
                case ResponseCode.ERROR:
                  this.errorMessage = "Ошибка";
                  break;
                case ResponseCode.ATTEMPT_LIMIT:
                  this.errorMessage = "Количество попыток исчерпано";
                  break;
                default:
                  this.errorMessage = "Неизвестный ответ";
              }
            })
            .catch(error => {
              console.log(error);
              const err: AxiosError = error as AxiosError;
              const details = !!err.message ? ` ( ${err.message})` : '';
              this.errorMessage = "Ошибка при запросе на проверку кода" + details;
            })
            .finally(() => {
              this.loading = false;
              if (!this.subscribeComplete) {
                setTimeout(() => {
                  this.$refs.inputCode.focus();
                }, 15)
              }
            });
      } else {
        if (emmitError) {
          this.errorMessage = "Введите номер телефона";
        }
      }
    },
    doVerifyCode(emmitError: boolean = true) {
      if (!!this.inputCode && this.inputCode.length >= 1 ) {
        console.info(this.inputMsisdn);
        console.info(this.subId());
        console.info(this.inputCode);
        this.dataService.verifyCode(this.inputMsisdn, this.subId(), this.inputCode)
            .then(response => {
              const result: ResponseCode = response;

              console.info("result doVerifyCode");
              console.info(result);
              // todo process

              switch (result) {
                case ResponseCode.SUCCESS_SEND:
                  this.errorMessage = "Код отправлен";
                  break;
                case ResponseCode.SUCCESS_SUB:
                  this.subscribeComplete = true;
                  break;
                case ResponseCode.UNSUCCESS_SUB:
                  this.errorMessage = "Ошибка при подписке";
                  break;
                case ResponseCode.NO_MONEY:
                  this.errorMessage = "Недостаточно средств";
                  break;
                case ResponseCode.BAD_CODE:
                  this.errorMessage = "Некорректный код";
                  break;
                case ResponseCode.ALREADY_SENT:
                  this.errorMessage = "Код уже отправлен";
                  break;
                case ResponseCode.RETRY_SEND:
                  this.errorMessage = "Код отправлен";
                  break;
                case ResponseCode.ERROR:
                  this.errorMessage = "Ошибка";
                  break;
                case ResponseCode.ATTEMPT_LIMIT:
                  this.errorMessage = "Количество попыток исчерпано";
                  break;
                default:
                  this.errorMessage = "Неизвестный ответ";
              }
            })
            .catch(error => {
              console.log(error);
              const err: AxiosError = error as AxiosError;
              const details = !!err.message ? ` ( ${err.message})` : '';
              this.errorMessage = "Ошибка при запросе на проверку кода" + details;
            })
            .finally(() => {
              this.loading = false;
              if (!this.subscribeComplete) {
                setTimeout(() => {
                  this.$refs.inputCode.focus();
                }, 15)
              }
            });
      } else {
        if (emmitError) {
          this.errorMessage = "Введите код из СМС";
        }
      }
    },
    doNext(emmitError: boolean = true) {
      // reset error
      this.errorMessage = null;
      if (!this.msisdn) {
        // no MSISDN - try to obtain
        this.doSendCode(emmitError);
      } else {
        // obtain SMS code
        this.doVerifyCode(emmitError);
      }
    },
    doClose() {
      this.onMsisdn(null);
      this.subscribeComplete = false;
      this.errorMessage = null;
      this.$emit('close');
    },
    onMsisdn(msisdn: string | null | undefined, ifExist: (string) => {} | null | undefined = null) {
      if (!!msisdn && msisdn.length > 0) {
        this.msisdn = msisdn;
        this.inputMsisdn = msisdn;
        if (!!ifExist) {
          ifExist(msisdn)
        }
        setTimeout(() => {
          this.$refs.inputCode.focus();
        }, 5)
      } else {
        this.msisdn = null;
        this.inputMsisdn = null;
        setTimeout(() => {
          this.$refs.inputMsisdn.focus();
        }, 5);
      }
    },
    subscribe() {
      this.subscriptionService.doSubscribe(this.sub.id);
    },
    requestAssistance() {
      this.subscriptionService.doRequestAssistance(this.sub);
    },
    requestRegulations() {
      this.subscriptionService.doRequestRegulations(this.sub);
    },
  },
  mounted() {
    this.subscriptionService = useSubscriptionService();
    this.dataService = useDataService();
    this.loading = false;
  },
  watch: {
    sub: function(newVal, oldVal) {
      if (!!newVal) {
        this.onMsisdn(this?.$root.msisdn, (msisdn) => this.doSendCode(false));
      }
    }
  }
})
