<template>
  <transition name="modal">
    <div class="modal-mask" v-show="!!sub" ref="modal" tabindex="0" @keydown.esc="close">
      <div class="modal-wrapper modal-sub-info">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="modal-title">{{ $t('message.result') }}</div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                <span class="icon" aria-hidden="true">&times;</span>
                <span class="text">{{$t('message.close') }}</span>
              </button>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">

              <div class="description">{{ $t('message.result_desc') }}</div>
              <div class="details">

                <div class="col-main">
                  <label for="resultYear" class="title">{{ $t('message.year') }}</label>
                  <select id="resultYear" class="form-control" @change="onYearOrNoneSelected()" v-model="year">
                    <option v-for="y in years" :value="y">
                      {{ y }}
                    </option>
                  </select>
                </div>
                <div class="col-details">
                  <!-- <div class="title">{{ $t('message.result') }}</div>-->
                  <label for="resultMonth" class="title">{{ $t('message.month') }}</label>
                  <select id="resultMonth" class="form-control" @change="onMonthSelected()" v-model="month">
                    <option v-for="m in months" :value="m">
                    {{ monthName($t, m) }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="details">

                <div class="col-main">
                  <p v-if="result">{{ $t('message.result') }} {{monthName($t, month)}} {{year}}</p>
                  <ol>
                    <li v-for="r in result">{{r.name}}</li>
                  </ol>
                </div>
              </div>
            </slot>
          </div>

          <!--<div class="modal-footer">
            <slot name="footer">
              <mf-button @click.stop="onMonthSelected" :text="`Показать победителей`" :style="`solid-button`"></mf-button>
            </slot>
          </div>-->
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {Language} from "../entity/Core";
import {QuizResultData, SubFact, SubItem} from "../entity/SubData";
import {useDataService} from "../store/DataService";
import MfButton from "./mf-button.vue";

export default defineComponent({
  name: 'SubscriptionResultModal',
  props: {
    'sub': Object, // SubItem,
  },
  components: {
    MfButton
  },
  data() {
    return {
      f: SubFact,
      subShadow: null,
      // dataService: [],
      years: [],
      months: [],
      year: null,
      month: null,
      result: null,
    }
  },
  watch: {
    sub: function(newVal, oldVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal != null) {
        this.subShadow = newVal;

        this.onYearOrNoneSelected();

        // this.show();
        setTimeout(() => {
          const primary = this.$refs?.action?.$refs?.subscribeButton;
          if (!!primary) {
            primary.focus();
          }
        }, 5)
      }
    }
  },
  methods: {
    subId(): string {
      return !this.subShadow ? null : this.subShadow.id;
    },
    attr(fact: SubFact): string {
      const si: SubItem = this.subShadow;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      // return !si ? null : si.facts[fact][Language.RU];
      return si?.facts?.[fact]?.[lang];
    },
    monthName(t, m) {
      let idx = "message.month_" + m;
      return t(idx)
      // return (new Date(2021, m, 1)).toLocaleString('default', {month: 'long'})
    },
    onYearOrNoneSelected() {
      let sid = this.subId();

      function uniqueArray1(ar) {
        let j = {};

        ar.forEach(function (v) {
          j[v + '::' + typeof v] = v;
        });

        return Object.keys(j).map(function (v) {
          return j[v];
        });
      }

      if (!!sid) {
        this.dataService.quizDates(sid, this.year).then((data: Array<String>) => {
          this.years = data.map(value => value.substring(0, value.indexOf("-")))
          this.months = data.map(value => parseInt(value.substring(value.indexOf("-") + 1)), 10)

          this.years = uniqueArray1(this.years)
          this.months = uniqueArray1(this.months)

          this.onMonthSelected();
        })
      }
    },

    onMonthSelected() {
      let sid = this.subId();
      if (!!sid && !!this.year && !!this.month) {
        this.dataService.quizData(sid, this.year, this.month).then((data: Array<QuizResultData>) => {
          this.result = data;
          console.info(this.result);
        })
      }
    },

    show() {
      // When the modal is shown, we want a fixed body
      const top = `-${window.scrollY}px`
      document.body.style.position = 'fixed';
      document.body.style.top = top;

      setTimeout(() => {
        this.$refs.modal.focus();
      }, 5)
    },
    clear() {
      this.years = null;
      this.months = null;
      this.year = null;
      this.month = null;
      this.result = null;
    },
    doClose() {
      this.clear();
      // ok
      this.onMsisdn(null);
      this.subscribeComplete = false;
      this.errorMessage = null;
      this.$emit('close');
    },
    close() {
      this.clear();
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);

      this.$emit('close');
    },
  },
  mounted() {
    this.dataService = useDataService();
  },
  created() {
    /*document.addEventListener('keydown', (e) => {
      if (!!this.subShadow && e.keyCode == 27) {
        this.close()
      }
    })*/
    // this.$nextTick().then(() => this.$root.$emit("modalShow"));
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
@import "./subscription-result";
.modal-sub-info .modal-body {
  @include subscription-result();
}
</style>