
import {defineComponent} from 'vue'
import {useDataService} from '../store/DataService';
import SubscriptionComponent from "../components/subscription.vue";
import SubscriptionDetailsModal from "../components/subscription-details-modal";
import SubscriptionProcessModal from "../components/subscription-process-modal";
import {SubFact, SubItem, SubsSuggest} from "../entity/SubData";
import * as UI from "../ui";
import {Language} from "../entity/Core";
import {Catalog} from "../entity/Content";

export default defineComponent({
  name: 'Search',
  props: ['catalogs', 'language', 'quizWithResult'],
  components: {
    SubscriptionComponent,
    SubscriptionDetailsModal,
    SubscriptionProcessModal
  },
  methods: {
    style(value: SubsSuggest, rowIndex: number, itemsInRow: number, subIndex: number) : Object {
      return UI.style(value?.subId, rowIndex, itemsInRow, subIndex)
    },
    rowStyle(itemsInRow: number, rowIndex: number) {
      return UI.rowStyle(itemsInRow, rowIndex)
    },
    row<T>(arr: Array<T>, size: number = 10): Array<Array<T>> {
      return UI.row(arr, size)
    },
    header(lang: Language): string {
      return (this.catalogs as Catalog[])
          .filter((cat: Catalog): boolean => cat.id === this.catalogId)
          .map((cat: Catalog): string => {
            switch (lang) {
              case Language.RU:
                return cat.labelRu;
              case Language.TJ:
                return cat.labelTj;
              default:
                return cat.labelRu;
            }
          })
          .find((__) => true);
    },
    hasResult(sub: SubsSuggest): Boolean {
      return !!sub ? this.quizWithResult.indexOf(sub.subId) >= 0 : false;
    },
    loadData(query: string) {
      this.input = query.substr(1);
      this.loading = true;
      this.loadedClass = '';
      if (this.input?.length > 0) {
        this.dataService.search(this.input, this.language)
            .then(response => {
              this.result = response
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => (this.loading = false));
      } else {
        // nothing to search
        this.result = []
        this.$router.push({path: "/"})
      }
    },
    showModalSubProcess(sub: SubItem) {
      this.modalSubProcess = sub;
    },
  },
  data() {
    return {
      modalSubProcess: null,
      dataService: [],
      result: [SubsSuggest],
      loadedClass: '',
      input: '',
      loading: true,
      errored: false,
      // $tc: null
    };
  },
  watch: {
    $route(to, from) {
      this.loadData(this.$route.hash)
    }
  },
  mounted() {
    this.dataService = useDataService();
    this.loadData(this.$route.hash)
  }
})
