<template>
  <div class="main-page">
    <div class="container">
      <div class="not-found" v-if="result?.length === 0">{{$t('message.search.not_found', {'input': input})}}</div>
      <div class="found" v-if="result?.length > 0">{{ $t('message.search.found', {'count': result?.length, 'input': input}, result?.length) }}</div>

      <div class="cf-integrated-communication-content"></div>
      <div class="subscription-wrapper" :class="[loadedClass]">
        <div v-for="(subSuggestRow, rowIndex) in row(result, 0/*4*/)" v-bind:class="rowStyle(subSuggestRow?.length, rowIndex)">
          <subscription-component
              v-for="(subsSuggest, subIndex) in subSuggestRow"
              :sub="subsSuggest.subItem"
              :style="style(subsSuggest, rowIndex, subSuggestRow?.length, subIndex)"
              :hasResult="hasResult(subsSuggest)"
              v-on:modal-sub-info="this.$root.showModalSubInfo($event)"
              v-on:modal-sub-process="showModalSubProcess($event)"
          />
        </div>
      </div>
    </div>
    <teleport to="#modals">
      <subscription-process-modal :sub="modalSubProcess" @close="modalSubProcess = null"></subscription-process-modal>
    </teleport>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {useDataService} from '../store/DataService';
import SubscriptionComponent from "../components/subscription.vue";
import SubscriptionDetailsModal from "../components/subscription-details-modal";
import SubscriptionProcessModal from "../components/subscription-process-modal";
import {SubFact, SubItem, SubsSuggest} from "../entity/SubData";
import * as UI from "../ui";
import {Language} from "../entity/Core";
import {Catalog} from "../entity/Content";

export default defineComponent({
  name: 'Search',
  props: ['catalogs', 'language', 'quizWithResult'],
  components: {
    SubscriptionComponent,
    SubscriptionDetailsModal,
    SubscriptionProcessModal
  },
  methods: {
    style(value: SubsSuggest, rowIndex: number, itemsInRow: number, subIndex: number) : Object {
      return UI.style(value?.subId, rowIndex, itemsInRow, subIndex)
    },
    rowStyle(itemsInRow: number, rowIndex: number) {
      return UI.rowStyle(itemsInRow, rowIndex)
    },
    row<T>(arr: Array<T>, size: number = 10): Array<Array<T>> {
      return UI.row(arr, size)
    },
    header(lang: Language): string {
      return (this.catalogs as Catalog[])
          .filter((cat: Catalog): boolean => cat.id === this.catalogId)
          .map((cat: Catalog): string => {
            switch (lang) {
              case Language.RU:
                return cat.labelRu;
              case Language.TJ:
                return cat.labelTj;
              default:
                return cat.labelRu;
            }
          })
          .find((__) => true);
    },
    hasResult(sub: SubsSuggest): Boolean {
      return !!sub ? this.quizWithResult.indexOf(sub.subId) >= 0 : false;
    },
    loadData(query: string) {
      this.input = query.substr(1);
      this.loading = true;
      this.loadedClass = '';
      if (this.input?.length > 0) {
        this.dataService.search(this.input, this.language)
            .then(response => {
              this.result = response
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => (this.loading = false));
      } else {
        // nothing to search
        this.result = []
        this.$router.push({path: "/"})
      }
    },
    showModalSubProcess(sub: SubItem) {
      this.modalSubProcess = sub;
    },
  },
  data() {
    return {
      modalSubProcess: null,
      dataService: [],
      result: [SubsSuggest],
      loadedClass: '',
      input: '',
      loading: true,
      errored: false,
      // $tc: null
    };
  },
  watch: {
    $route(to, from) {
      this.loadData(this.$route.hash)
    }
  },
  mounted() {
    this.dataService = useDataService();
    this.loadData(this.$route.hash)
  }
})
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/mixins";
@import "../components/subscription";

@media (min-width: $screen-sm) {
  @include section-align-3col();
}

@media (max-width: $screen-sm) {
  @include section-align-vertical();
}

.not-found,
.found {
  text-align: center;
  margin: 0 0 32px;
}

.subscription-wrapper {
  @include subscription-wrapper-impl();
}

</style>
