
import {defineComponent} from 'vue';
import {SubFact, SubItem, SubsSuggest} from "../entity/SubData";
import {useDataService} from "../store/DataService";
import {Language} from "../entity/Core";
import {LoadContainer, LoadState} from "../entity/util/Load";

enum State {
  COMPACT,
  SEARCH_AREA
}

export default defineComponent({
  name: 'SearchComponent',
  props: ['styleClass', 'language'],
  emits: ['modalSubInfo', 'modal-sub-info'],
  data() {
    return {
      dataService: [],
      f: SubFact,
      State: State,
      LoadState: LoadState,

      state: State.COMPACT,
      input: "",

      search: LoadContainer.initial<String, Array<SubsSuggest>>([]),
    }
  },
  mounted() {
    this.dataService = useDataService();
  },
  watch: {
    'language': function (locale) {
      // if (this.active && this.showResult && this.result?.length > 0) {
      if (this.state === State.SEARCH_AREA && this.search.state !== LoadState.NOT_INIT) {
        this.doSearch()
      }
    }
  },
  methods: {
    /**
     * Текстовое значение атрибута подписки
     * @param subItem объект подписки
     * @param fact искомый факт
     * @param lang локаль пользователя
     */
    attr(subItem: SubItem, fact: SubFact, lang: Language): string {
      const locAttr = subItem?.facts?.[fact]?.[lang];
      return locAttr?.length > 0 ? locAttr : subItem?.facts?.[fact]?.[Language.RU];
    },

    /**
     * Заголовок элемента, в нужной локали, поскольку SubsSuggest уже является функцией от языка запроса при поиске
     */
    title(subsSuggest: SubsSuggest): string {
      return `<span> ${subsSuggest.title} </span>`
    },

    /**
     * Выполнение поиска для текущего input-а
     * @param internal будет ли поиск отображён в текущем компоненте или будет выполнена переадресация на отдельную страницу
     * @see input текущая модель для поиска
     */
    doSearch(internal: boolean = true) {
      if (internal) {
        if (this.state === State.SEARCH_AREA) {
          const searchTerm = this.input;
          const searchInitial = this.search;

          // this.search = LoadContainer.load(searchTerm, searchInitial)
          setTimeout(() => {
            if (this.search.stateIn([LoadState.LOAD_IN_PROGRESS_PRIMARY, LoadState.LOAD_IN_PROGRESS_SECONDARY])) {
              this.search = LoadContainer.load(searchTerm, searchInitial)
            }
          }, 500)

          this.dataService.search(this.input, this.language)
              .then(response => {
                this.search = new LoadContainer<String, Array<SubsSuggest>>(
                    searchTerm,
                    response,
                    response.length === 0 ? LoadState.LOAD_COMPLETE_EMPTY : LoadState.LOAD_COMPLETE_DATA
                )
              })
              .catch(error => {
                console.log(error);
                this.search = new LoadContainer<String, Array<SubsSuggest>>(
                    searchTerm,
                    searchInitial.result,
                    LoadState.LOAD_ERROR
                )
              })
        } else {
          this.doActive()
        }
      } else {
        this.state = State.COMPACT
        this.$router.push({path: '/search/', hash: `#${this.input}`});
      }

    },
    doActive() {
      this.state = State.SEARCH_AREA
      setTimeout(() => {
        // this.$refs.searchInput.$el.focus()
        this.$refs.searchInput.focus()
      }, 100)
    },
    doOnChange() {
      if (this.state === State.SEARCH_AREA && this.search.criteria !== this.input) {
        if (this.input?.length >= 3) {
          this.doSearch()
        }
      }
    },

    /**
     * Обработка выбора результата поиска – показываем модальное окно с подпиской
     * @param item
     */
    onSelect(item: SubsSuggest) {
      this.doReset();
      this.$emit('modal-sub-info', item.subId);
      // // this.$emit('modal-sub-info', item.subItem);

      // this.active = false;
      // this.showResult = false
      // this.$router.push({path: '/search/', hash: `#${item.title}`});
    },

    /**
     * Сброс состояния поиска
     */
    doReset() {
      this.input = ""
      this.state = State.COMPACT
      this.search = LoadContainer.initial<String, Array<SubsSuggest>>([])
      // this.active = false;
      // this.showResult = false
    },
  }
})
