
import {defineComponent} from 'vue'
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData";
import SubscriptionAction from "./subscription-action";

export default defineComponent({
  name: 'SubscriptionDetailsModalComponent',
  props: {
    'sub': Object, // SubItem,
    'hasResult': Boolean
  },
  components: {
    SubscriptionAction
  },
  data() {
    return {
      f: SubFact,
      subShadow: null
    }
  },
  watch: {
    sub: function(newVal, oldVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal != null) {
        this.subShadow = newVal;
        this.show();
        setTimeout(() => {
          const primary = this.$refs?.action?.$refs?.subscribeButton;
          if (!!primary) {
            primary.focus();
          }
        }, 5)
      }
    }
  },
  methods: {
    subId(): string {
      return !this.subShadow ? null : this.subShadow.id;
    },
    attr(fact: SubFact): string {
      const si: SubItem = this.subShadow;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      // return !si ? null : si.facts[fact][Language.RU];
      return si?.facts?.[fact]?.[lang];
    },
    show() {
      // When the modal is shown, we want a fixed body
      const top = `-${window.scrollY}px`
      document.body.style.position = 'fixed';
      document.body.style.top = top;

      setTimeout(() => {
        this.$refs.modal.focus();
      }, 5)
    },
    close() {
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);

      this.$emit('close');
    },
  },
  created() {
    /*document.addEventListener('keydown', (e) => {
      if (!!this.subShadow && e.keyCode == 27) {
        this.close()
      }
    })*/
    // this.$nextTick().then(() => this.$root.$emit("modalShow"));
  }
})
