import "bootstrap/scss/bootstrap.scss";
import "../scss/main.scss";

import {createApp, inject} from "vue";
import Vuex from 'vuex';
import {router} from './router';
import {createI18n, useI18n} from 'vue-i18n/dist/vue-i18n.esm-bundler.js'
// import i18next from 'i18next';
// import VueI18Next from '@panter/vue-i18next';

// import {DataService, stateSymbol} from './store/DataService';
import {dataServiceSymbol, createDataService} from './store/DataService';
import {subscriptionServiceSymbol, createSubscriptionService} from './store/SubsctiptionService';

import BodyComponent from './app.vue'

declare global {
    interface Window {
        // todo: replace with a service
        routePromise: Promise<boolean> | boolean;
    }
}

const locales = {
    ru: {
        message: {
            subscribe: 'Подписаться',
            assistance: 'Задать вопрос',
            regulations: 'Скачать регламент',
            result: 'Победители',
            result_desc: 'Выберите месяц и год и вы увидите победителей',
            year: 'Год',
            month: 'Месяц',
            close: 'Закрыть',
            feedback: 'Обратная связь',
            continueReading: 'Читать дальше',
            load_more: 'Загрузить ещё',

            novelty: 'Новинки',
            categories: 'Разделы',
            news: 'Новости',

            month_1: "Январь",
            month_2: "Февраль",
            month_3: "Март",
            month_4: "Апрель",
            month_5: "Май",
            month_6: "Июнь",
            month_7: "Июль",
            month_8: "Август",
            month_9: "Сентябрь",
            month_10: "Октябрь",
            month_11: "Ноябрь",
            month_12: "Декабрь",

            search: {
                title: "Поиск",
                found: "По тексту «{input}» найден {count} результат",
                found_0: "По тексту «{input}» найден {count} результат",
                found_1: "По тексту «{input}» найдено {count} результата",
                found_2: "По тексту «{input}» найдено {count} результатов",
                not_found: "По тексту «{input}» результатов не найдено"
            },

            sub: {
                description: 'Описание',
                price: 'Цена',
                enable: 'Подключение',
                disable: 'Отключение',
                info: 'Важная информация'
            }
        }
    },
    tj: {
        message: {
            subscribe: 'Обунашавӣ',
            assistance: 'Саволдиҳӣ',
            regulations: 'Зеркашии коидахо',
            result: 'Ғолибон',
            result_desc: 'Моҳ ва солро интихоб кунед, ва шумо ғолибонро хоҳед дид',
            year: 'Соли',
            month: 'Моҳ',
            close: 'Пӯшидан',
            feedback: 'Алоқаи мутақобил',
            continueReading: 'Маълумоти бештар',
            load_more: 'Бештар нишон диҳед',

            novelty: 'Молҳои нав',
            categories: 'Қисмҳо',
            news: 'Ахбор',

            month_1: "Январ",
            month_2: "Феврал",
            month_3: "Март",
            month_4: "Апрел",
            month_5: "Май",
            month_6: "Июн",
            month_7: "Июл",
            month_8: "Август",
            month_9: "Сентябр",
            month_10: "Октябр",
            month_11: "Ноябр",
            month_12: "Декабр",

            search: {
                title: "Ҷустуҷӯ",
                found: "Матни \"{input}\" натиҷаи {count} ёфт",
                found_plural: "Матни \"{input}\" натиҷаи {count} ёфт",
                not_found: "Барои матни \"{input}\" ягон натиҷае ёфт нашуд"
            },

            sub: {
                description: 'Тавсиф',
                price: 'Нарх',
                enable: 'Пайвасткунӣ',
                disable: 'Қатънамоӣ',
                info: 'Маълумоти муҳим'
            }
        }
    }
};

/*
i18next.init({
    lng: "ru",
    resources: {
        ru: { translation: locales.ru },
        tj: { translation: locales.tj }
    }
});
const i18n = new VueI18Next(i18next);
*/

const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    messages: locales
})

createApp(BodyComponent)
    .use(router)
    .use(Vuex)
    .use(i18n)
    .provide(dataServiceSymbol, createDataService())
    .provide(subscriptionServiceSymbol, createSubscriptionService())
    .mount('#app')