<template>
  <form class="search" :class="[
      {'active': state === State.SEARCH_AREA},
      {'show-result': search.state !== LoadState.NOT_INIT}, styleClass
      ]" @click.stop="doActive">
    <div class="search-wrapper">
      <input :placeholder="[$t('message.search.title')]" ref="searchInput" v-model="input"
             v-on:keydown="state = State.SEARCH_AREA"
             v-on:keyup.prevent="doOnChange"
             @keydown.enter.prevent="doSearch(false)"
             @keydown.esc.prevent="doReset"/>
      <button type="reset" @click.stop.prevent="doReset"></button>
      <button type="submit" @click.stop.prevent="doSearch(false)"></button>
      <a href="#" class="label" @click.prevent>{{$t('message.search.title')}}</a>
    </div>
    <a href="#" class="reset" @click.stop.prevent="doReset">Отменить</a>

    <div class="search-result" v-if="state === State.SEARCH_AREA && search.stateIn([LoadState.LOAD_COMPLETE_DATA, LoadState.LOAD_IN_PROGRESS_SECONDARY])">
<!--    <div class="search-result" v-if="state === State.SEARCH_AREA && search.stateIn([LoadState.LOAD_COMPLETE_DATA])">-->
      <a href="#" v-for="(item, index) in search.result" @click.prevent="onSelect(item)" v-html="title(item)"></a>
    </div>
    <div class="search-result" v-if="state === State.SEARCH_AREA && (search.stateIn([
        LoadState.LOAD_ERROR,
        LoadState.LOAD_COMPLETE_EMPTY,
        LoadState.LOAD_IN_PROGRESS_PRIMARY/*, LoadState.LOAD_IN_PROGRESS_SECONDARY*/
        ]) || (search.state === LoadState.LOAD_IN_PROGRESS_SECONDARY && search.result.length === 0))">
      <span class="not-found" v-if="search.state === LoadState.LOAD_ERROR">Ошибка</span>
      <span class="not-found" v-if="search.state === LoadState.LOAD_COMPLETE_EMPTY">По тексту «{{input}}» результатов не найдено</span>
<!--      <span class="not-found" v-if="search.stateIn([LoadState.LOAD_IN_PROGRESS_PRIMARY, LoadState.LOAD_IN_PROGRESS_SECONDARY])">Загрузка...</span>-->
      <span class="not-found" v-if="search.state === LoadState.LOAD_IN_PROGRESS_PRIMARY || (search.state === LoadState.LOAD_IN_PROGRESS_SECONDARY && search.result.length === 0)">Загрузка...</span>
    </div>
  </form>
<!--  <div class="search-result" v-for="(item, index) in result">-->
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {SubFact, SubItem, SubsSuggest} from "../entity/SubData";
import {useDataService} from "../store/DataService";
import {Language} from "../entity/Core";
import {LoadContainer, LoadState} from "../entity/util/Load";

enum State {
  COMPACT,
  SEARCH_AREA
}

export default defineComponent({
  name: 'SearchComponent',
  props: ['styleClass', 'language'],
  emits: ['modalSubInfo', 'modal-sub-info'],
  data() {
    return {
      dataService: [],
      f: SubFact,
      State: State,
      LoadState: LoadState,

      state: State.COMPACT,
      input: "",

      search: LoadContainer.initial<String, Array<SubsSuggest>>([]),
    }
  },
  mounted() {
    this.dataService = useDataService();
  },
  watch: {
    'language': function (locale) {
      // if (this.active && this.showResult && this.result?.length > 0) {
      if (this.state === State.SEARCH_AREA && this.search.state !== LoadState.NOT_INIT) {
        this.doSearch()
      }
    }
  },
  methods: {
    /**
     * Текстовое значение атрибута подписки
     * @param subItem объект подписки
     * @param fact искомый факт
     * @param lang локаль пользователя
     */
    attr(subItem: SubItem, fact: SubFact, lang: Language): string {
      const locAttr = subItem?.facts?.[fact]?.[lang];
      return locAttr?.length > 0 ? locAttr : subItem?.facts?.[fact]?.[Language.RU];
    },

    /**
     * Заголовок элемента, в нужной локали, поскольку SubsSuggest уже является функцией от языка запроса при поиске
     */
    title(subsSuggest: SubsSuggest): string {
      return `<span> ${subsSuggest.title} </span>`
    },

    /**
     * Выполнение поиска для текущего input-а
     * @param internal будет ли поиск отображён в текущем компоненте или будет выполнена переадресация на отдельную страницу
     * @see input текущая модель для поиска
     */
    doSearch(internal: boolean = true) {
      if (internal) {
        if (this.state === State.SEARCH_AREA) {
          const searchTerm = this.input;
          const searchInitial = this.search;

          // this.search = LoadContainer.load(searchTerm, searchInitial)
          setTimeout(() => {
            if (this.search.stateIn([LoadState.LOAD_IN_PROGRESS_PRIMARY, LoadState.LOAD_IN_PROGRESS_SECONDARY])) {
              this.search = LoadContainer.load(searchTerm, searchInitial)
            }
          }, 500)

          this.dataService.search(this.input, this.language)
              .then(response => {
                this.search = new LoadContainer<String, Array<SubsSuggest>>(
                    searchTerm,
                    response,
                    response.length === 0 ? LoadState.LOAD_COMPLETE_EMPTY : LoadState.LOAD_COMPLETE_DATA
                )
              })
              .catch(error => {
                console.log(error);
                this.search = new LoadContainer<String, Array<SubsSuggest>>(
                    searchTerm,
                    searchInitial.result,
                    LoadState.LOAD_ERROR
                )
              })
        } else {
          this.doActive()
        }
      } else {
        this.state = State.COMPACT
        this.$router.push({path: '/search/', hash: `#${this.input}`});
      }

    },
    doActive() {
      this.state = State.SEARCH_AREA
      setTimeout(() => {
        // this.$refs.searchInput.$el.focus()
        this.$refs.searchInput.focus()
      }, 100)
    },
    doOnChange() {
      if (this.state === State.SEARCH_AREA && this.search.criteria !== this.input) {
        if (this.input?.length >= 3) {
          this.doSearch()
        }
      }
    },

    /**
     * Обработка выбора результата поиска – показываем модальное окно с подпиской
     * @param item
     */
    onSelect(item: SubsSuggest) {
      this.doReset();
      this.$emit('modal-sub-info', item.subId);
      // // this.$emit('modal-sub-info', item.subItem);

      // this.active = false;
      // this.showResult = false
      // this.$router.push({path: '/search/', hash: `#${item.title}`});
    },

    /**
     * Сброс состояния поиска
     */
    doReset() {
      this.input = ""
      this.state = State.COMPACT
      this.search = LoadContainer.initial<String, Array<SubsSuggest>>([])
      // this.active = false;
      // this.showResult = false
    },
  }
})
</script>
<style lang="scss"> /*scoped*/
@import "../../scss/variables";
@import "../../scss/mixins";

@mixin search-impl($offset-vertical, $offset-horizontal, $color-text, $color-placeholder, $icon-search, $image-width, $adjustment-right) {
  input {
    position: relative;

    $offset-right-with-image: $offset-horizontal + $image-width - $adjustment-right + 2px;
    padding: $offset-vertical $offset-right-with-image $offset-vertical $offset-horizontal;
    color: $color-text;
    
    &::placeholder {
      color: $color-placeholder;
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
      color: $color-placeholder;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color-placeholder;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color-placeholder;
      opacity: 1;
    }
    :-ms-input-placeholder { /*aaa Internet Explorer 10+ */
      color: $color-placeholder;
    }

  }

  [type="submit"] {
    margin-right: $offset-horizontal;
    right: -1 * $adjustment-right;

    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    width: $image-width;
    height: $image-width;
    background-image: url($icon-search);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:hover {
      background-color: transparent;
      filter: brightness(1.2)
    }
  }
}

form.search {

  $icon-search: './icons/search.svg';
  $offset-horizontal: 12px;
  $offset-vertical: 18px;
  $color-text: #000000;
  $color-placeholder: #BBBBBB;
  $icon-size: 32px;

  //padding-left: 18px;
  //padding-right: 18px;
  padding: 18px;

  width: 100%;

  display: flex;
  flex-flow: row nowrap;

  & > * {
    flex-basis: content;
  }

  & > .search-wrapper {
    position: relative;
    flex-grow: 1;

    input {
      display: block;
      width: 100%;
      padding: 8px 12px;

      &:focus {
        outline: none;
      }
    }
  }




  button {
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 4px;
    right: 8px;
    padding: 0;

    display: block;
    width: $icon-size;
    height: $icon-size;
    background-size: $icon-size $icon-size;
  }

  button[type="submit"] {
    background-image: url('./icons/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -1px;
  }

  button[type="reset"] {
    background-image: url('./icons/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: 100ms opacity ease-in-out, 100ms right linear;
  }

  input {
    display: block;
    height: 40px;

    //background-color: #E5EAFC;
    border: 1px solid #00AA00;
    //border-radius: 19px;
    @include search-impl($offset-vertical, $offset-horizontal, $color-text, $color-placeholder, $icon-search, 48px, 0px);
  }

  .reset {
    line-height: 40px;
    overflow: hidden;
    margin-left: 0;
    transition: 100ms margin-left ease-in-out;
    flex-basis: 0;
  }

  /*&:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }*/

  &.active {
    /*input {
      padding-left: 42px;
    }*/
    button[type="reset"] {
      right: $icon-size + $icon-size * 0.3;
      //right: initial;
      opacity: 1;
    }
  }

  @media (max-width: $screen-sm) {
    // mobile
    .label {
      display: none;
    }

    input {
      background-color: #E5EAFC;
      border: 1px solid transparent;
      border-radius: 19px;
    }

    &.active {
      input {
        border: 1px solid #00AA00;
      }

      button[type="reset"] {
        display: none;
      }

      .reset {
        flex-basis: content;
        margin-left: 10px;
      }
    }

    &.active {
      .search-result {
        margin-top: 50px;
      }
    }
  }

  @media (min-width: $screen-sm) {
    // desktop
    padding: 0 32px 0 0;

    .reset {
      display: none;
    }

    input {
      border-radius: 10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    & > .search-wrapper {
      display: flex;
      & > * {
        flex: 1;
        position: unset;
      }

      button[type="submit"] {
        margin-top: 1px;
        background-size: 26px 26px;
        filter: grayscale(1);
      }

      button[type="reset"] {
        display: none;
        background-size: 28px 28px;
        opacity: 0.8;
        top: 6px;
      }

      .label {
        margin-left: -30px;
        padding-left: 35px;
        line-height: 32px;
        color: black;
        z-index: 2;
        &:hover {
          text-decoration: none;
        }
      }
      input {
        display: none;
        border: 0;
        //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
      }
    }

    &.active {
      & > .search-wrapper {

        button[type="reset"] {
          display: block;
        }

        input {
          display: block;
        }

        position: absolute;
        right: 65px;
        left: 164px;
        margin: 0 auto;
        max-width: 900px;

        top: 20px;
        overflow: visible;

        & > button {
          display: block;
          position: absolute;
        }

        .label {
          display: none;
          margin-right: -100%;
        }
      }
    }

    &.active {
      &.show-result .search-wrapper {
        input {
          border-radius: 10px 10px 0 0;
        }
      }
      .search-result {
        border-radius: 0 0 10px 10px;
        display: block;
        margin-top: -14px;
        //border-top: 0;

        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border: 0;

        position: absolute;
        right: 65px;
        left: 164px;
        margin: 0 auto;
        max-width: 900px;
        top: 60px;
      }
    }
  }
}

.search-result {

  position: absolute;
  background-color: rgba(242, 245, 255, 0.99);
  border-radius: 6px;
  z-index: 10;
  overflow: hidden;
  //box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #e4e7f3;
  //width: calc(100% - 1.5 * 18px);
  left: 15px;
  right: 15px;
  max-width: 1185px;
  margin: 0 auto;

  & > * {
    display: block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 6px;
    color: #242533;

    &a:hover {
      color: #242533;
      text-decoration: none;
    }

    @media (min-width: $screen-sm) {
      &a:hover {
        background: rgba(0, 200, 100, 0.2);
      }
    }

    &.not-found {
      text-align: center;
    }
  }
}
</style>
