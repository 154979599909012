<template>
  <swiper class="news novelity"
          :loop="true"
          :slides-per-view="slidesPerView"
          :centeredSlides="true"
          :spaceBetween="12"
          :initialSlide="1"
          :mousewheel="false"
          :rewind="true"
          :updateOnWindowResize="true"
          :pagination="{clickable: true}"
          >
    <swiper-slide v-for="(item, index) in slidesData" :virtualIndex="index" :class="`style-${item.target.id}`">
      <subscription-component @click.stop="showModalSubInfo(item.target.id)"
          :sub="item.target" :hasResult="false" v-on:modal-sub-info="showModalSubInfo($event)"/>
    </swiper-slide>
  </swiper>

  <div class="mobile-swiper novelity">
    <div v-for="(item, index) in slidesData" :class="`style-${item.target.id}`">
      <subscription-component @click.stop="showModalSubInfo(item.target.id)"
                              :sub="item.target" :hasResult="false" v-on:modal-sub-info="showModalSubInfo($event)"/>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

// import Swiper core and required components
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Virtual, Mousewheel} from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";
import {Novelty} from "../entity/Content";

import SubscriptionComponent from "../components/subscription.vue";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Mousewheel]);

export default defineComponent( {
  name: 'MainNovelty',
  components: {
    Swiper,
    SwiperSlide,
    SubscriptionComponent
  },
  props: ['content'],
  watch: {
    content: function(newVal, oldVal) {
      this.slidesData = (!!newVal && newVal.length > 0) ? newVal
          : new Novelty(new SubItem('', false, new Map<SubFact, Map<Language, string>>()), '')
      this.slidesPerView = (!!this.slidesData && this.slidesData.length > 0) ? 'auto' : '1';
    }
  },
  data() {
    return {
      sf: SubFact,
      slidesData: [],
      slidesPerView: 3
    }
  },
  methods: {
    attr(si: SubItem, fact: SubFact): string {
      const lang = Language[this.$i18n.locale.toUpperCase()];
      return si?.facts?.[fact]?.[lang];
    },
    showModalSubInfo(subId: string) {
      this.$emit('modal-sub-info', subId);
    }
  }
})
</script>
<style lang="scss">
@import "../../scss/mixins";
.main-page .swiper.news {
  height: unset;
  margin: -30px -15px 15px;
  padding: 30px 15px 0;

  .swiper-wrapper {
    overflow: visible !important;

    .swiper-slide {
      overflow: visible !important;

      .subscription {
        transition: transform .3s cubic-bezier(0, 0.8, 0.04, 0.98), box-shadow .3s cubic-bezier(0, 0.8, 0.04, 0.98), scale .3s cubic-bezier(0, 0.8, 0.04, 0.98);
        box-shadow: 2px 2px 12px #80808075;
        transform: scale(0.85);
      }

      &.swiper-slide-active,
      &.swiper-slide-prev,
      &.swiper-slide-next {
        .subscription {
          transform: scale(1);
          &:hover {
            z-index: 10;
            transform: scale(1.1);
          }
        }
      }

      &.swiper-slide-active .subscription:hover {
        box-shadow: 0 7px 7px #80808075;
      }

      &.swiper-slide-prev .subscription:hover {
        box-shadow: 3px 5px 7px #80808075;
      }

      &.swiper-slide-next .subscription:hover {
        box-shadow: -3px 5px 7px #80808075;
      }
    }
  }
}


.mobile-swiper {
  display: none;
}

@media (max-width: $screen-sm) {
  .swiper {
    display: none;
  }

  .mobile-swiper {
    overflow-x: auto;
    overflow-y: hidden;

    display:flex;

    margin-left: -20px;
    margin-right: -20px;

    & > * {

      .title {
        width: unset;
      }

      .subscription {
        min-width: 200px;
        min-height: 200px;
      }

      padding-right: 20px;
      padding-bottom: 20px;

      &:nth-child(1) {
        padding-left: 20px;
      }
    }
  }
}


.news-block {
  overflow: hidden;
  .img-block {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
  }
}
.novelity {
  .sub-card {
    cursor: pointer;
    min-height: 200px;
    .actions, .subscribe-button {
      display: none;
    }
  }
}
</style>