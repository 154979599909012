<template>
  <footer>
    <div class="container">
      <div class="copyright">© 2022, ЗАО "ТТ мобайл" МегаФон-Точикистон</div>
      <!--<a href="#" class="offer">Оферта</a>-->
      <a href="https://live.megafon.tj/" class="feedback">{{$t('message.feedback') }}</a>
<!--        <div class="logo-block"><img src="/img/logo-footer.png" alt=""></div>-->
      <div class="social">
        <a href="https://ok.ru/megafontj" class="social-ok"></a>
        <a href="https://vk.com/megafontj" class="social-vk"></a>
        <a href="https://www.instagram.com/megafontj/" class="social-ig"></a>
        <a href="https://www.facebook.com/megafontj" class="social-fb"></a>
        <a href="https://www.linkedin.com/company/10595528/" class="social-in"></a>
        <a href="https://twitter.com/MegafonTj" class="social-tw"></a>
      </div>
      <div class="logo"></div>
    </div>
  </footer>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FooterComponent',
})
</script>
