<template>
  <transition name="modal">
    <!--
    name="modal"
    name="fade-shrink-in" appear
    -->

    <div class="modal-mask" v-show="!!sub" ref="modal" tabindex="0">  <!--@keydown.esc="close"-->
      <div class="modal-wrapper modal-sub-info">
        <div class="modal-container">

<!--    <div class="modal" tabindex="-1" role="dialog" v-show="!!sub" ref="modal">-->
<!--      <div class="modal-dialog modal-sub-info" role="document">-->
<!--        <div class="modal-content">-->

            <div class="modal-header">
              <slot name="header">
                <div class="modal-title">{{attr(f.TITLE)}}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                  <span class="icon" aria-hidden="true">&times;</span>
                  <span class="text">{{$t('message.close') }}</span>
                </button>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">

                <div class="description" v-html="attr(f.DESC_FULL)"></div>
                <div class="details">
                  <div class="col-main">
                    <div class="title">{{ $t('message.sub.price') }}</div>
                    <span v-html="attr(f.PRICE)"></span>

                    <div class="title" v-if="!!attr(f.SUB) || !!attr(f.UNSUB)">{{ $t('message.sub.enable') }}  / {{ $t('message.sub.disable') }} </div>
                    <div class="sub-title" v-if="!!attr(f.SUB)">{{ $t('message.sub.enable') }} </div>
                    <span v-html="attr(f.SUB)"></span>
                    <div class="sub-title" v-if="!!attr(f.UNSUB)">{{ $t('message.sub.disable') }}</div>
                    <span v-html="attr(f.UNSUB)"></span>
                  </div>
                  <div class="col-details" v-if="!!attr(f.DETAILS)">
                    <div class="title">{{ $t('message.sub.info') }}</div>
                    <span v-html="attr(f.DETAILS)"></span>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <subscription-action :sub="sub" :hasResult="hasResult" ref="action"/>
                <!--<button class="modal-default-button" @click="$emit('close')">OK</button>-->
              </slot>
            </div>
          </div>
        </div>
      </div>
  </transition>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData";
import SubscriptionAction from "./subscription-action";

export default defineComponent({
  name: 'SubscriptionDetailsModalComponent',
  props: {
    'sub': Object, // SubItem,
    'hasResult': Boolean
  },
  components: {
    SubscriptionAction
  },
  data() {
    return {
      f: SubFact,
      subShadow: null
    }
  },
  watch: {
    sub: function(newVal, oldVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal != null) {
        this.subShadow = newVal;
        this.show();
        setTimeout(() => {
          const primary = this.$refs?.action?.$refs?.subscribeButton;
          if (!!primary) {
            primary.focus();
          }
        }, 5)
      }
    }
  },
  methods: {
    subId(): string {
      return !this.subShadow ? null : this.subShadow.id;
    },
    attr(fact: SubFact): string {
      const si: SubItem = this.subShadow;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      // return !si ? null : si.facts[fact][Language.RU];
      return si?.facts?.[fact]?.[lang];
    },
    show() {
      // When the modal is shown, we want a fixed body
      const top = `-${window.scrollY}px`
      document.body.style.position = 'fixed';
      document.body.style.top = top;

      setTimeout(() => {
        this.$refs.modal.focus();
      }, 5)
    },
    close() {
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);

      this.$emit('close');
    },
  },
  created() {
    /*document.addEventListener('keydown', (e) => {
      if (!!this.subShadow && e.keyCode == 27) {
        this.close()
      }
    })*/
    // this.$nextTick().then(() => this.$root.$emit("modalShow"));
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
@import "./subscription-details";
.modal-sub-info .modal-body {
  @include subscription-detail();
}
</style>