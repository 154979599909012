import {SubsSuggest} from "../SubData";

export enum LoadState {
    NOT_INIT,
    LOAD_IN_PROGRESS_PRIMARY,
    LOAD_IN_PROGRESS_SECONDARY,
    LOAD_COMPLETE_DATA,
    LOAD_COMPLETE_EMPTY,
    LOAD_ERROR
}

export class LoadContainer<I, O> {
    constructor(
        public readonly criteria: I | null,
        public readonly result: O,
        public readonly state: LoadState,
    ) {
    }

    static initial<I, O>(initialResult: O) : LoadContainer<I, O> {
        return new LoadContainer<I, O>(null, initialResult, LoadState.NOT_INIT)
    }

    static load<I, O>(searchTerm: I, prevState: LoadContainer<I, O>) : LoadContainer<I, O> {
        return new LoadContainer<I, O>(searchTerm, prevState.result,
            prevState.state == LoadState.NOT_INIT ? LoadState.LOAD_IN_PROGRESS_PRIMARY : LoadState.LOAD_IN_PROGRESS_SECONDARY
        )
    }

    stateIn(states: Array<LoadState>) {
        return states.includes(this.state)
    }
}