import {Language} from "./Core";

export enum SubFact {
    TITLE = 'TITLE',
    DESC_COVER = 'DESC_COVER',
    DESC_FULL = 'DESC_FULL',
    SUB = 'SUB',
    UNSUB = 'UNSUB',
    PRICE = 'PRICE',
    DETAILS = 'DETAILS',
    REGULATION = 'REGULATION',
}

export enum ResponseCode {
    SUCCESS_SEND = 'SUCCESS_SEND',
    SUCCESS_SUB = 'SUCCESS_SUB',
    NO_MONEY = 'NO_MONEY',
    UNSUCCESS_SUB = 'UNSUCCESS_SUB',
    BAD_CODE = 'BAD_CODE',
    ALREADY_SENT = 'ALREADY_SENT',
    RETRY_SEND = 'RETRY_SEND',
    ERROR = 'ERROR',
    ATTEMPT_LIMIT = 'ATTEMPT_LIMIT'
}

export class SubItem {
    constructor(
        public readonly id: string,
        public readonly visible: boolean,
        public readonly facts: Map<SubFact, Map<Language, string>>,
    ) {
    }

    static fact(subItem: SubItem, fact: SubFact, lang: Language): string {
        const locAttr = subItem?.facts?.[fact]?.[lang];
        if (locAttr?.length > 0) {
            return locAttr;
        } else {
            return subItem?.facts?.[fact]?.[Language.RU]
        }
    }
}

export class QuizResultDates {
    constructor(
        public readonly data: String
    ) {
    }
}

export class QuizResultData {
    constructor(
        public readonly number: Number,
        public readonly name: String,
    ) {
    }
}

export class SubsSuggest {
    constructor(
        public readonly subId: String,
        public readonly title: String,
        public readonly description: String,
        public readonly subItem: SubItem | null
    ) {
    }
}