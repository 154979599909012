<template>
  <div class="main-page">
    <div class="container">
      <banner v-on:modal-sub-info="this.$root.showModalSubIdInfo($event)" :content="banners"/>
      
      <div class="new-products-wrap">
        <div class="title-lined">
          <div class="title">{{ $t('message.novelty') }}</div>
        </div>
        <div class="cf-integrated-communication-content"></div>
        <main-novelty v-on:modal-sub-info="this.$root.showModalSubIdInfo($event)" :content="novelties"/>
      </div>

      <div class="categories-wrap">
        <div class="title-lined">
          <div class="title">{{ $t('message.categories') }}</div>
        </div>
        <main-categories :catalogs="catalogs" :language="language"/>
      </div>

      <div class="news-wrap">
        <div class="title-lined">
          <div class="title">{{ $t('message.news') }}</div>
        </div>
        <main-news :content="news" :language="language"/>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import Banner from "../components/banner.vue";
  import MainNovelty from "../components/main-novelty.vue";
  import SubscriptionProcessModal from "../components/subscription-process-modal";
  import MainCategories from "../components/main-categories";
  import MainNews from "../components/main-news";
  import {SubItem} from "../entity/SubData";
  import {useDataService} from "../store/DataService";
  import {CorePack, HomePack} from "../entity/Content";
  import Search from "../components/search-area.vue";
  export default defineComponent( {
    name: 'Home',
    props: ['catalogs', 'language'],
    components: {
      Search,
      Banner,
      MainNovelty,
      MainCategories,
      MainNews,
      SubscriptionProcessModal
    },
    data() {
      return {
        modalSubProcess: null,
        dataService: [],
        banners: [],
        novelties: [],
        news: [],
        loading: true,
        errored: false
      };
    },
    mounted() {
      this.dataService = useDataService();
      // this.loadData(this.$route.meta.header, this.$route.name)
      this.loading = true;
      this.dataService.homePack()
          .then(response => {
            const {banners, novelties, news} = (response as HomePack)
            this.banners = banners;
            this.novelties = novelties;
            this.news = news;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  })
</script>
