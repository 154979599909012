<template>
  <header v-on:lang="applyLanguage($event)">
    <div class="container">
      <div class="top-block">
        <div class="cf-integrated-header-content-1"></div>
        <div class="d-flex justify-content-between align-items-center">
          <router-link to="/" class="logo-block"><img src="/img/logo-header.png" alt="logo"></router-link>
          <div class="d-flex align-items-center">
            <nav class="d-md-flex d-none flex-md-nowrap flex-md-row flex-column">
              <router-link :class="`${cat.id}`" v-for="cat in catalogs" :to="`/${cat.id}`">{{label(cat, language)}}</router-link>
            </nav>
            <search :style-class="'desktop'" :language="language" v-on:modal-sub-info="showModalSubInfo($event)"/>
            <div class="lang-block d-flex lang-block align-items-center">
              <div class="icon icon-earth-globe"></div>
              <a href="#" class="lang" :class="{active: language === lng.RU}" @click.prevent="lang('ru')">RU</a>
              <div class="sep">|</div>
              <a href="#" class="lang" :class="{active: language === lng.TJ}" @click.prevent="lang('tj')">TJ</a>
            </div>
          </div>
        </div>
        <div class="cf-integrated-header-content-2"></div>
      </div>
      <div class="bot-block d-md-none d-flex justify-content-between">
<!--        <router-link v-for="cat in catalogs" :to="`/${cat.id}`" class="icon" v-bind:class="`icon-${cat.id}`">{{label(cat, language)}}</router-link>-->
<!--        <router-link :class="`${cat.id}`" v-for="cat in catalogs" :to="`/${cat.id}`">{{label(cat, language)}}</router-link>-->
        <router-link :class="`${cat.id}`" v-for="cat in catalogs" :to="`/${cat.id}`">{{label(cat, language)}}</router-link>
      </div>
    </div>
    <search :style-class="'mobile'" :language="language" v-on:modal-sub-info="showModalSubInfo($event)"/>
  </header>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import {Language} from "../entity/Core";
  import {SubFact} from "../entity/SubData";
  import {useSubscriptionService} from "../store/SubsctiptionService";
  import {Catalog} from "../entity/Content";
  import Search from "./search-area.vue";
  export default defineComponent({
    name: 'HeaderComponent',
    props: ['language', 'catalogs'],
    emits: ['modalSubInfo', 'modal-sub-info'],
    components: {
      Search
    },
    data() {
      return {
        lng: Language
      }
    },
    methods: {
      lang(lang: string): void {
        this.$emit('lang', Language[lang.toUpperCase()])
      },
      label(cat: Catalog, lang: Language): string {
        switch (lang) {
          case Language.RU:
            return cat.labelRu;
          case Language.TJ:
            return cat.labelTj;
          default:
            return cat.labelRu;
        }
      },
      applyLanguage(lang) {
        console.info("handle");
        console.info(lang);
        this.language = lang
      },
      showModalSubInfo(subId: string) {
        this.$emit('modal-sub-info', subId);
      }
    },
    mounted() {
      // this.this.$root.$on('lang', () => {
      //   console.info('rehandle');
      // })
    }
  })
</script>
<style lang="scss">
@import "../../scss/variables";
@import "../../scss/mixins";

.icon-popular { /*.icon-like;*/
  &:before {
     content: "\e915";
   }
}
.icon-portals { /*.icon-video;*/
  &:before {
    content: "\e914";
  }
}
.icon-quizzes { /*.icon-question-cat;*/
  &:before {
    content: "\e935";
  }
}
.icon-dating { /*.icon-heart;*/
  &:before {
    content: "\e912";
  }
}
.icon-education { /*.icon-study;*/
  &:before {
    content: "\e913";
    header & {
      font-size: 16px;
    }
    .category-inner & {
      font-size: 32px;
      margin-top: 6px;
      margin-left: 6px;
    }
  }

}
.icon-other { /*.icon-services;*/
  &:before {
    content: "\e911";
  }
}

@media (min-width: $screen-sm) {
  .top-block {
    position: relative;
  }

  .search-result {
    top: 74px;
    right: 90px;
    left: 190px;

    background-color: #ffffff;
  }

}
</style>
