import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import Home from "../pages/home.vue";
import Search from "../pages/search.vue";

const routes = [ {
        path: '/',
        name: 'home',
        component: Home
    }, {
        path: '/search/',
        name: 'search',
        component: Search
    }
];

export const router = createRouter({
    history: createWebHashHistory('/'),
    // history: createWebHistory('/'),
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    routes
});

router.beforeEach((to, from, next) => {
    const routePromise = window.routePromise;
    if (!!routePromise && routePromise instanceof Promise) {
        routePromise.then((__) => {
            // reload with all routes mounted
            router.replace(to);
        });
    } else {
        next();
    }
})
