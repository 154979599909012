
import { defineComponent } from 'vue'

// import Swiper core and required components
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
// import 'swiper/swiper.scss';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import {Banner, Novelty} from "../entity/Content";
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default defineComponent( {
  name: 'Banner',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['content'],
  watch: {
    content: function(newVal, oldVal) {
      this.slidesData = (!!newVal && newVal.length > 0) ? newVal
          : new Banner('', '', '', 0);
      this.slidesPerView = (!!this.slidesData && this.slidesData.length > 0) ? 'auto' : '';
    }
  },
  data() {
    return {
      sf: SubFact,
      slidesData: [],
      slidesPerView: 1
    }
  },
  methods: {
    showModalSubInfo(subId: string) {
      this.$emit('modal-sub-info', subId);
    }
  }
})
