<template>
  <div class="main-banner">
    <swiper class="main bn"
            :loop="true"
            :slides-per-view="slidesPerView"
            :updateOnWindowResize="true"
            :spaceBetween="12"
            :autoplay="{delay: 7000}"
            :pagination="{clickable: true}">

      <swiper-slide v-for="(item, index) in slidesData">
        <div class="banner" @click="showModalSubInfo(item.subId)">
          <div class="frame-desktop" :style="{'background-image': 'url(' + item.desktopImgPath + ')'}"></div>
          <div class="cf-integrated-content-main"></div>
          <div class="frame-mobile" :style="{'background-image': 'url(' + item.mobileImgPath + ')'}"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

// import Swiper core and required components
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
// import 'swiper/swiper.scss';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import {Banner, Novelty} from "../entity/Content";
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default defineComponent( {
  name: 'Banner',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['content'],
  watch: {
    content: function(newVal, oldVal) {
      this.slidesData = (!!newVal && newVal.length > 0) ? newVal
          : new Banner('', '', '', 0);
      this.slidesPerView = (!!this.slidesData && this.slidesData.length > 0) ? 'auto' : '';
    }
  },
  data() {
    return {
      sf: SubFact,
      slidesData: [],
      slidesPerView: 1
    }
  },
  methods: {
    showModalSubInfo(subId: string) {
      this.$emit('modal-sub-info', subId);
    }
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
.main-page .swiper.main.bn {
  height: unset;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  border-radius: 0;
  
  .banner {
    display: block;

    border-radius: $border-radius;
    overflow: hidden;

    height: 100%;
    max-width: 100%;
    position: relative;

    margin-left: 0;
    margin-right: 0;

    & > * {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 26.27%;
    }
  }

  .frame-desktop, .frame-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .frame-desktop, .frame-mobile {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .frame-desktop {
    display: block;
  }
  .frame-mobile {
    display: none;
  }

  @media (max-width: $screen-sm) {
    .banner:after {
      content: "";
      display: block;
      padding-bottom: 74.44%;
    }

    .frame-desktop {
      display: none;
    }
    .frame-mobile {
      display: block;
    }
  }
}
</style>
