export class PageImpl<T> {
    public readonly content: T;
    public readonly empty: boolean;
    public readonly first: boolean;
    public readonly last: boolean;
    public readonly number: number;
    public readonly numberOfElements: number;
    public readonly pageable: Pageable;

    public readonly size: number;
    public readonly sort: Sort;
    public readonly totalElements: number;
    public readonly totalPages: number;
}

export class Pageable {
    public readonly offset: number;
    public readonly pageNumber: number;
    public readonly pageSize: number;
    public readonly paged: boolean;
    public readonly sort: Sort;
    public readonly unpaged: boolean;
}

export class Sort {
    public readonly empty: boolean;
    public readonly sorted: boolean;
    public readonly unsorted: boolean;
}

export function pages(currPage: number, totalPages: number, availablePages: number = totalPages): Array<number> {
    const l = Math.max(totalPages - 1, 1);
    let result: Set<number> = new Set<number>();
    let cnt = availablePages;
    let stepAround = 0;
    const end = () : boolean => cnt === 0 || result.size === totalPages
    const put = (newValue: number, label: string | null = null) : boolean => {
        const alignedValue = Math.min(Math.max(newValue, 0), l);
        const added = !result.has(alignedValue) && !!result.add(alignedValue);
        /*if (!!label) {
            console.info(`--- ${label} ${added ? 'added' : 'skip'}: ${newValue} -> ${alignedValue}`);
        }*/
        return added;
    }
    while (!end()) {
        if (put(currPage, 'current')) {
            // current
            cnt--;
        } else if (put(currPage + 1, 'next')) {
            // next
            stepAround++;
            cnt--;

        } else if (put(currPage - 1, 'prev')) {
            // prev
            stepAround++;
            cnt--;

        } else if (put(0, 'first')) {
            // first
            cnt--;

        } else if (put(l, 'last')) {
            // last
            cnt--;

        } else {
            while (!end()) {
                const direction: -1 | 1 = (stepAround % 2) === 0 ? 1 : -1;
                const delta = direction * (Math.floor(stepAround / 2));
                if (put(currPage + delta, `delta ${stepAround} as ${delta}`)) {
                    cnt--;
                }
                stepAround++;
            }
        }
    }
    return Array.from(result).sort();
}