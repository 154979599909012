export function style(subItemId: String, rowIndex: number, itemsInRow: number, subIndex: number): Object {
    let style = {};
    style[`style-${subItemId?.toLowerCase()}`] = true; // name style

    // few elements special case
    if (itemsInRow <= 2) {
        style[`sub-row`] = true;
        if (subIndex % 2 === 0) {
            style[`sub-row-green-colorful`] = true;
        } else {
            style[`sub-row-violet-colorful`] = true;
        }
        return style;
    }

    // index style
    if (rowIndex % 2 === 0) {
        if (subIndex === 0) {
            style[`sub-row-green-colorful`] = true;
            style[`sub-row`] = true;
        } else if (subIndex === 1) {
            style[`sub-row-violet`] = true;
            style[`sub-row`] = true;
        } else {
            style[`sub-col-${(subIndex % 2) === 0 ? 'green' : 'violet'}`] = true;
            style[`sub-col`] = true;
        }
    } else {
        if (subIndex === 2) {
            style[`sub-row-violet-colorful`] = true;
            style[`sub-row`] = true;
        } else if (subIndex === 3) {
            style[`sub-row-green`] = true;
            style[`sub-row`] = true;
        } else {
            style[`sub-col-${(subIndex % 2) === 1 ? 'green' : 'violet'}`] = true;
            style[`sub-col`] = true;
        }
    }
    return style;
}

export function rowStyle(itemsInRow: number, rowIndex: number) {
    let style = {};
    style[`subscription-row-wrapper`] = true;
    if (itemsInRow <= 2) {
        style[`simple-row`] = true;
    }
    return style;
}

export function row<T>(arr: Array<T>, size: number = 10): Array<Array<T>> {
    if (size <= 0) {
        return [arr]
    } else {
        return arr.reduce((rows, key, index) => (index % size == 0 ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows, []);
    }
}