
import { defineComponent } from 'vue'
import {Language} from "../entity/Core";
import {Catalog} from "../entity/Content";
export default defineComponent( {
  name: 'MainCategories',
  props: ['catalogs', 'language'],
  watch: {
    '$i18n.locale': function (locale) {
      this.lang = Language[locale.toUpperCase()];
    }
  },
  data() {
    return {
    }
  },
  methods: {
    label(cat: Catalog, lang: Language): string {
      switch (lang) {
        case Language.RU:
          return cat.labelRu;
        case Language.TJ:
          return cat.labelTj;
        default:
          return cat.labelRu;
      }
    }
  },
})
