<template>
<!--  <body :class="[languageClass]">-->
<!--  <div class="application" :class="[languageClass, modalClass]">-->
    <HeaderComponent v-on:lang="setLanguage($event)" :language="language" :catalogs="catalogs" v-on:modal-sub-info="showModalSubIdInfo($event)"></HeaderComponent>
    <router-view v-bind="routeViewProps"/>
    <div id="modals"></div>
    <FooterComponent></FooterComponent>
<!--  </div>-->
<!--  </body>-->
  <teleport to="#modals">
    <subscription-details-modal :sub="modalSubInfo" @close="modalSubInfo = null"></subscription-details-modal>
  </teleport>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  import HeaderComponent from './components/header.vue'
  import FooterComponent from './components/footer.vue'
  import {Language} from "./entity/Core";
  import {useDataService} from "./store/DataService";
  import {Catalog, CorePack} from "./entity/Content";
  import Section from "./pages/section.vue";
  import SubscriptionDetailsModal from "./components/subscription-details-modal";
  import {SubItem} from "./entity/SubData";
  // import Home from '../pages/Home.vue'
  // import NewsComponent from './news.vue'
  export default defineComponent({
    name: 'App',
    data: () => ({
      dataService: [],
      loading: true,
      menuOpen: true,
      language: Language.RU,
      languageClass: 'lang-ru',
      modalClass: '',
      msisdn: null,
      catalogs: [],
      quizWithResult: [],
      modalSubInfo: null,
      subscriptionsCache: new Map<string, SubItem>(),
    }),
    computed: {
      routeViewProps() {
        if (this.$route.name === 'home') {
          return {
            catalogs: this.catalogs,
            language: this.language
          }
        } else {
          return {
            catalogs: this.catalogs,
            language: this.language,
            quizWithResult: this.quizWithResult
          }
        }
      }
    },
    methods: {
      toggleMenu() {
        this.menuOpen = !this.menuOpen;
      },
      modalShow() {
        console.info("modalShow");
        document.body.classList.add('has-modal');
      },
      modalHide() {
        document.body.classList.remove('has-modal');
      },
      setLanguage(lang: Language) {
        if (this.language !== lang) {
          const prevLangClass = this.languageClass;
          const nextLangClass = 'lang-' + lang.toLowerCase()
          this.language = lang
          this.languageClass = nextLangClass;
          this.$i18n.locale = lang.toLowerCase();
          document.body.classList.remove(prevLangClass);
          document.body.classList.add(nextLangClass);
        }

        // console.info("reemit");
        // this.$emit('lang', lang)
      },
      showModalSubInfo(sub: SubItem) {
        this.modalSubInfo = sub;
      },
      showModalSubIdInfo(subId: string) {
        const act = (sub: SubItem) => this.modalSubInfo = sub;
        const cachedSub = this.subscriptionsCache.get(subId);
        if (!!cachedSub) {
          act(cachedSub);
        } else {
          this.dataService.subInfo(subId).then((sub) => {
            if (!!sub) {
              this.subscriptionsCache.set(subId, sub);
              act(sub);
            }
          });
        }
      }
    },
    beforeCreate() {
      this.$nextTick().then(() => document.body.classList.add(this.languageClass));
    },
    mounted() {
      this.dataService = useDataService();
      this.loading = true;
      // console.info("mounted");
      window.routePromise = new Promise((resolve, reject) => {
        this.dataService.corePack()
            .then(response => {
              const {msisdn, catalogs, quizWithResult} = (response as CorePack)
              catalogs.map(catalog => new Object({
                "path": `/${catalog.id}`,
                "name": `${catalog.id}`,
                "component": Section,
                "meta": {
                  "catalogId": `${catalog.id}`
                }
              })).forEach(route => this.$router.addRoute(route));

              this.msisdn = msisdn;
              this.catalogs = catalogs;
              this.quizWithResult = quizWithResult;

              // todo: test
              // this.msisdn = "123123";
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              // console.info("app.vue: promise resolved");
              this.loading = false;

              resolve(true);
              window.routePromise = true;
            });
      });

    },
    components: {
      HeaderComponent,
      FooterComponent,
      SubscriptionDetailsModal
    }
  })
</script>
<style lang="scss">
body.has-modal {
  overflow: hidden;
}
</style>