import {Language} from "./Core";

export class News {
    constructor(
        public readonly id: string,
        public readonly subscriptionId: String | null,
        public readonly visible: boolean,
        public readonly priority: number,
        public readonly created: Date,
        public readonly facts: Map<NewsFact, Map<Language, string>>,
    ) {
    }
}

export enum NewsFact {
    TITLE = 'TITLE',
    SHORT_DESC = 'SHORT_DESC',
    FULL_DESC = 'FULL_DESC'
}