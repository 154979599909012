
  import { defineComponent } from 'vue';
  import {Language} from "../entity/Core";
  import {SubFact} from "../entity/SubData";
  import {useSubscriptionService} from "../store/SubsctiptionService";
  import {Catalog} from "../entity/Content";
  import Search from "./search-area.vue";
  export default defineComponent({
    name: 'HeaderComponent',
    props: ['language', 'catalogs'],
    emits: ['modalSubInfo', 'modal-sub-info'],
    components: {
      Search
    },
    data() {
      return {
        lng: Language
      }
    },
    methods: {
      lang(lang: string): void {
        this.$emit('lang', Language[lang.toUpperCase()])
      },
      label(cat: Catalog, lang: Language): string {
        switch (lang) {
          case Language.RU:
            return cat.labelRu;
          case Language.TJ:
            return cat.labelTj;
          default:
            return cat.labelRu;
        }
      },
      applyLanguage(lang) {
        console.info("handle");
        console.info(lang);
        this.language = lang
      },
      showModalSubInfo(subId: string) {
        this.$emit('modal-sub-info', subId);
      }
    },
    mounted() {
      // this.this.$root.$on('lang', () => {
      //   console.info('rehandle');
      // })
    }
  })
