
import { defineComponent } from 'vue'

// import Swiper core and required components
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Virtual, Mousewheel} from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";
import {Novelty} from "../entity/Content";

import SubscriptionComponent from "../components/subscription.vue";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Mousewheel]);

export default defineComponent( {
  name: 'MainNovelty',
  components: {
    Swiper,
    SwiperSlide,
    SubscriptionComponent
  },
  props: ['content'],
  watch: {
    content: function(newVal, oldVal) {
      this.slidesData = (!!newVal && newVal.length > 0) ? newVal
          : new Novelty(new SubItem('', false, new Map<SubFact, Map<Language, string>>()), '')
      this.slidesPerView = (!!this.slidesData && this.slidesData.length > 0) ? 'auto' : '1';
    }
  },
  data() {
    return {
      sf: SubFact,
      slidesData: [],
      slidesPerView: 3
    }
  },
  methods: {
    attr(si: SubItem, fact: SubFact): string {
      const lang = Language[this.$i18n.locale.toUpperCase()];
      return si?.facts?.[fact]?.[lang];
    },
    showModalSubInfo(subId: string) {
      this.$emit('modal-sub-info', subId);
    }
  }
})
