
import {defineComponent} from 'vue'
import {useSubscriptionService} from '../store/SubsctiptionService';
import SubscriptionProcessModal from "./subscription-process-modal";
import SubscriptionResultModal from "./subscription-result-modal";
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";

function isRegulationPresent(subItem: SubItem, lang: Language = Language.RU) : boolean {
  return !!subItem ? !!SubItem.fact(subItem, SubFact.REGULATION, lang) : false
}

export default defineComponent({
  name: 'SubscriptionAction',
  props: {
    'sub': Object, // SubItem,
    'hasResult': Boolean,
    'defAction': String,
  },
  components: {
    SubscriptionProcessModal,
    SubscriptionResultModal,
  },
  data() {
    return {
      isAssistance: true,
      isRegulation: false,
      isResult: false,
      modalSub: null,
      modalResult: null,
      subscriptionService: null
    }
  },
  watch: {
    sub: function(newVal: SubItem, oldVal: SubItem) {
      // console.info("newval sub " + JSON.stringify(newVal));
      this.doUpdate(newVal, this.hasResult, this.defAction)
    },
    hasResult: function(newVal: Boolean, oldVal: Boolean) {
      this.doUpdate(this.sub, newVal, this.defAction)
    },
    defAction: function(newVal: String, oldVal: String) {
      this.doUpdate(this.sub, this.hasResult, newVal)
    },
  },
  methods: {
    doUpdate: function (sub: SubItem, hasResult: Boolean, defAction: String) {
      const lang = Language[this.$i18n.locale.toUpperCase()];

      let hasRegulation = false;
      if (sub != null) {
        // hasRegulation = !!SubItem.fact(sub, SubFact.REGULATION, lang)
        hasRegulation = isRegulationPresent(sub, lang)
        // this.isAssistance = !this.hasRegulation
      }
      switch (defAction) {
        case 'assistance':
          this.isAssistance = true
          this.isRegulation = false
          this.isResult = false
          break
        case 'regulations':
          if (hasRegulation) {
            this.isRegulation = true
            this.isAssistance = false
          } else {
            this.isRegulation = false
            this.isAssistance = true
          }
          this.isResult = false
          break
        case 'result':
          if (hasResult) {
            this.isResult = true
            this.isRegulation = false
            this.isAssistance = false
          } else {
            this.isAssistance = true
            this.isRegulation = false
            this.isResult = false
          }
          break
        default:
          this.isAssistance = true
          this.isRegulation = hasRegulation
          this.isResult = hasResult
      }
    },
    subscribe() {
      this.modalSub = this.sub;
      // this.subscriptionService.doSubscribe(this.sub.id);
    },
    requestAssistance() {
      this.subscriptionService.doRequestAssistance(this.sub);
    },
    requestRegulations() {
      this.subscriptionService.doRequestRegulations(this.sub);
    },
    requestResult() {
      this.modalResult = this.sub;
    },
  },
  mounted() {
    const lang = Language[this.$i18n.locale.toUpperCase()];
    this.subscriptionService = useSubscriptionService();
    this.doUpdate(this.sub, this.hasResult, this.defAction)
  }
})
