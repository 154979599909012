
  import { defineComponent } from 'vue'
  import Banner from "../components/banner.vue";
  import MainNovelty from "../components/main-novelty.vue";
  import SubscriptionProcessModal from "../components/subscription-process-modal";
  import MainCategories from "../components/main-categories";
  import MainNews from "../components/main-news";
  import {SubItem} from "../entity/SubData";
  import {useDataService} from "../store/DataService";
  import {CorePack, HomePack} from "../entity/Content";
  import Search from "../components/search-area.vue";
  export default defineComponent( {
    name: 'Home',
    props: ['catalogs', 'language'],
    components: {
      Search,
      Banner,
      MainNovelty,
      MainCategories,
      MainNews,
      SubscriptionProcessModal
    },
    data() {
      return {
        modalSubProcess: null,
        dataService: [],
        banners: [],
        novelties: [],
        news: [],
        loading: true,
        errored: false
      };
    },
    mounted() {
      this.dataService = useDataService();
      // this.loadData(this.$route.meta.header, this.$route.name)
      this.loading = true;
      this.dataService.homePack()
          .then(response => {
            const {banners, novelties, news} = (response as HomePack)
            this.banners = banners;
            this.novelties = novelties;
            this.news = news;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  })
