
import { defineComponent } from 'vue'
import {Novelty} from "../entity/Content";
import {News, NewsFact} from "../entity/News";
import {SubFact, SubItem} from "../entity/SubData";
import {Language} from "../entity/Core";
import NewsArticleModalComponent from "./news-article-modal.vue"
import {useDataService} from "../store/DataService";
import {PageImpl, pages} from "../entity/spring/Pagination";
export default defineComponent( {
  name: 'MainNews',
  components: {
    NewsArticleModalComponent
  },
  props: ['content', 'language'],
  watch: {
    content: function(newVal, oldVal) {
      this.page = (!!newVal) ? newVal : [];
      this.newsContent.push(...this.page?.content);
    },
   /* '$i18n.locale': function (locale) {
      this.lang = Language[locale.toUpperCase()];
    }*/
  },
  data() {
    return {
      // lang: Language,
      nf: NewsFact,
      page: null as PageImpl<News>,
      newsContent: [],
      dataService: [],
      modalNewsArticle: null
    }
  },
  methods: {
    attr(news: News, fact: NewsFact, lang: Language): string {
      const locAttr = news?.facts?.[fact]?.[lang];
      if (locAttr?.length > 0) {
        return locAttr;
      } else {
        return news?.facts?.[fact]?.[Language.RU]
      }
    },
    toPage(newPageNumber: number) {
      if (newPageNumber === this.page.number) {
        // skip
      } else {
        this.dataService.news(newPageNumber).then((newPage) => {
          this.page = newPage;
          this.newsContent = newPage.content;
        })
      }
    },
    loadMore() {
      if (!this.page.last) {
        this.dataService.news(this.page.number + 1).then((newPage) => {
          this.page = newPage;
          const content = this.page?.content
          this.newsContent.push(...content);
        })
      }
    },
    sequence() {
      return pages(this.page.number, this.page.totalPages, 5);
    },
    showModal(newsArticle: News) {
      this.modalNewsArticle = newsArticle;
      // this.$emit('modal-sub-info', subId);
    }
  },
  mounted() {
    this.dataService = useDataService();
  }
})
