import {reactive, provide, inject} from 'vue';
// import axios from 'axios';
const {default: axiosAny} = require('axios');
const axios: AxiosInstance = axiosAny as AxiosInstance;
import {
    ResponseCode,
    SubItem,
    QuizResultDates,
    QuizResultData,
    SubsSuggest
} from "../entity/SubData";
import {AxiosInstance, AxiosResponse} from "axios/index.d.ts";
import {Banner, CorePack, HomePack} from "../entity/Content";
import {PageImpl} from "../entity/spring/Pagination";
import {News} from "../entity/News";
import {Language} from "../entity/Core";

function catalogContent(catalogId: string): Promise<Array<SubItem>> {
    return new Promise<Array<SubItem>>((resolve, reject) => {
        // todo: loader count
        axios.get<Array<SubItem>>(`/api/subs/catalog/${catalogId}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function subInfo(subId: string): Promise<SubItem | null> {
    return new Promise<SubItem | null>((resolve, reject) => {
        // todo: loader count
        axios.get<SubItem>(`/api/subs/${subId}`).then(response => {
            if ((response as AxiosResponse<SubItem>).status === 404) {
                resolve(null);
            } else {
                const data = response.data;
                resolve(data)
            }
        }).catch(error => {
            reject(error);
        });
    });
}

function banners(catalogId: string): Promise<Array<Banner>> {
    return new Promise<Array<Banner>>((resolve, reject) => {
        // todo: loader count
        axios.get<Array<Banner>>(`/api/banners?source=${catalogId}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function news(page: number): Promise<PageImpl<News>> {
    return new Promise<PageImpl<News>>((resolve, reject) => {
        // todo: loader count
        axios.get<PageImpl<News>>(`/api/news?page=${page}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function corePack(): Promise<CorePack> {
    return new Promise<CorePack>((resolve, reject) => {
        // todo: loader count
        axios.get<CorePack>(`/api/pack/core`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function homePack(): Promise<HomePack> {
    return new Promise<HomePack>((resolve, reject) => {
        // todo: loader count
        axios.get(`/api/pack/home`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function sendCode(msisdn: string, subId: string): Promise<ResponseCode> {
    return new Promise<ResponseCode>((resolve, reject) => {
        // todo: loader count
        const noPlusMsisdn = msisdn.replace("+", "");
        axios.get<ResponseCode>(`/api/getCode?MSISDN=${noPlusMsisdn}&subid=${subId}`).then(response => {
        // axios.post<ResponseCode>(`/api/getCode`, `msisdn=${msisdn}&subid=${subId}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}
function verifyCode(msisdn: string, subId: string, code: string): Promise<ResponseCode> {
    return new Promise<ResponseCode>((resolve, reject) => {
        // todo: loader count
        const noPlusMsisdn = msisdn.replace("+", "");
        axios.get<ResponseCode>(`/api/checkCode?MSISDN=${noPlusMsisdn}&subid=${subId}&code=${code}`).then(response => {
        // axios.post<ResponseCode>(`/api/checkCode`,`msisdn=${msisdn}&subid=${subId}&code=${code}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function quizDates(quizSubsId: string, year: number): Promise<Array<String>> {
    return new Promise<Array<String>>((resolve, reject) => {
        // todo: loader count
        axios.get<Array<String>>(`/api/quiz-result/${quizSubsId}` + (!!year ? `/${year}` : "")).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function quizData(quizSubsId: string, year: number, month: number): Promise<Array<QuizResultData>> {
    return new Promise<Array<QuizResultData>>((resolve, reject) => {
        // todo: loader count
        axios.get<Array<QuizResultData>>(`/api/quiz-result/${quizSubsId}/${year}/${month}`).then(response => {
            const data = response.data;
            resolve(data)
        }).catch(error => {
            reject(error);
        });
    });
}

function search(query: String, lang: Language): Promise<SubsSuggest[]> {
        return new Promise<SubsSuggest[]>((resolve, reject) => {
            axios.get<SubsSuggest[]>(`/api/lookup-suggests?q=${query}&lang=${lang}`).then(response => {
                const data = response.data;
                resolve(data)
            }).catch(error => {
                reject(error);
            });
        });
    }

export const dataServiceSymbol = Symbol('dataService');
export const createDataService = () => reactive({
    loading: 0,
    catalogContent: (routerName: string): Promise<Array<SubItem>> => {
        return catalogContent(routerName);
    },
    subInfo: (subId: string): Promise<SubItem | null> => {
        return subInfo(subId);
    },
    banners: (routerName: string): Promise<Array<Banner>> => {
        return banners(routerName);
    },
    corePack: (): Promise<CorePack> => {
        return corePack();
    },
    news: (page: number): Promise<PageImpl<News>> => {
        return news(page);
    },
    homePack: (): Promise<HomePack> => {
        return homePack();
    },
    sendCode: (msisdn: string, subId: string): Promise<ResponseCode> => {
        return sendCode(msisdn, subId);
    },
    verifyCode: (msisdn: string, subId: string, code: string): Promise<ResponseCode> => {
        return verifyCode(msisdn, subId, code);
    },
    quizDates: (quizSubsId: string, year: number): Promise<Array<String>> => {
        return quizDates(quizSubsId, year);
    },
    quizData: (quizSubsId: string, year: number, month: number): Promise<Array<QuizResultData>> => {
        return quizData(quizSubsId, year, month);
    },
    search: (query: String, lang: Language): Promise<SubsSuggest[]> => {
        return search(query, lang);
    }
});
export const useDataService = () => inject(dataServiceSymbol);
export const provideDataService = () => provide(
    dataServiceSymbol,
    createDataService()
);