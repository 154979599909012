<template>
  <div class="subscription" :class="[style, openState]">
    <!--<div class="sub-card desktop" @click="showModalSubInfo">
      <div class="wrap">
        <div class="title" >{{attr(f.TITLE)}}</div>
        <div class="description" v-html="attr(f.DESC_COVER)"></div>
        <mf-button @click.stop="showModalSubProcess" :text="`${$t('message.subscribe')}`" :style="`subscribe-button`"></mf-button>
      </div>
      <div class="background"></div>
      <div class="art"></div>
      <div class="icon"></div>
    </div>-->
    <div class="sub-card desktop has-background" @click="showModalSubInfo">
      <div class="title" >{{attr(f.TITLE)}}</div>
      <div class="description" v-html="attr(f.DESC_COVER)"></div>
      <mf-button @click.stop="showModalSubProcess" :text="`${$t('message.subscribe')}`" :style="`subscribe-button`"></mf-button>
      <div class="background"></div>
      <div class="art"></div>
      <div class="icon"></div>
    </div>
    <div class="sub-card mobile has-background">
      <div class="title" >{{attr(f.TITLE)}}</div>
      <div class="description" v-html="attr(f.DESC_COVER)"></div>
      <div class="actions">
        <button class="description-button" @click.stop="toggleDescription"></button> <!-- star -->
        <button class="price-button" @click.stop="togglePrice"></button> <!-- tjs -->
        <button class="terms-button" @click.stop="toggleTerms"></button> <!-- on-off -->
        <button class="info-button" @click.stop="toggleInfo" v-if="!!attr(f.DETAILS)"></button> <!-- info -->
      </div>
      <button class="subscribe-button btn" @click.stop="showModalSubProcess">
        <span>{{$t('message.subscribe') }}</span>
      </button>
      <div class="background"></div>
      <div class="art"></div>
      <div class="icon"></div>
    </div>
    <div class="details">
      <div v-show="descriptionOpen">
        <div class="title">{{ $t('message.sub.description') }}</div>
        <span v-html="attr(f.DESC_FULL)"></span>
        <subscription-action :sub="sub" :hasResult="hasResult" :defAction="'assistance'"/>
      </div>
      <div v-show="priceOpen">
        <div class="title">{{ $t('message.sub.price') }}</div>
        <span v-html="attr(f.PRICE)"></span>
        <subscription-action :sub="sub" :hasResult="hasResult" :defAction="'assistance'"/>
      </div>
      <div v-show="termsOpen">
        <div class="title">{{ $t('message.sub.enable') }} / {{ $t('message.sub.disable') }}</div>
        <div class="sub-title">{{ $t('message.sub.enable') }} </div>
        <span v-html="attr(f.SUB)"></span>
        <div class="sub-title">{{ $t('message.sub.disable') }}</div>
        <span v-html="attr(f.UNSUB)"></span>
        <subscription-action :sub="sub" :hasResult="hasResult" :defAction="'regulations'"/>
      </div>
      <div v-show="infoOpen" v-if="!!attr(f.DETAILS)">
        <div class="title">{{ $t('message.sub.info') }}</div>
        <span v-html="attr(f.DETAILS)"></span>
        <subscription-action :sub="sub" :hasResult="hasResult" :defAction="'result'"/>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {Language} from "../entity/Core";
import {SubFact, SubItem} from "../entity/SubData"
import {useSubscriptionService} from '../store/SubsctiptionService';
import SubscriptionAction from "./subscription-action.vue";
import MfButton from "./mf-button.vue";

export default defineComponent({
  name: 'SubscriptionComponent',
  props: {
    'sub': Object, // SubItem,
    'style': Object, // string: boolean
    'hasResult': Boolean
  },
  components: {
    MfButton,
    SubscriptionAction
  },
  data() {
    return {
      subscriptionService: null,
      f: SubFact,
      openState: "",
      descriptionOpen: false,
      priceOpen: false,
      termsOpen: false,
      infoOpen: false
    }
  },
  methods: {
    attr(fact: SubFact): string {
      const si: SubItem = this.sub;
      const lang = Language[this.$i18n.locale.toUpperCase()];
      return si?.facts?.[fact]?.[lang];
    },
    showModalSubInfo() {
      // console.info(this.sub); // proxy instead of object
      this.$emit('modal-sub-info', this.sub);
    },
    showModalSubProcess() {
      this.$emit('modal-sub-process', this.sub);
    },
    toggleDescription() {
      this.descriptionOpen = !this.descriptionOpen;
      this.openState = this.descriptionOpen ? 'descriptionOpen' : '';
      this.priceOpen = false;
      this.termsOpen = false;
      this.infoOpen = false;
    },
    togglePrice() {
      this.priceOpen = !this.priceOpen;
      this.openState = this.priceOpen ? 'priceOpen' : '';
      this.descriptionOpen = false;
      this.termsOpen = false;
      this.infoOpen = false;
    },
    toggleTerms() {
      this.termsOpen = !this.termsOpen;
      this.openState = this.termsOpen ? 'termsOpen' : '';
      this.descriptionOpen = false;
      this.priceOpen = false;
      this.infoOpen = false;
    },
    toggleInfo() {
      this.infoOpen = !this.infoOpen;
      this.openState = this.infoOpen ? 'infoOpen' : '';
      this.descriptionOpen = false;
      this.priceOpen = false;
      this.termsOpen = false;
    },
  },
  mounted() {
    this.subscriptionService = useSubscriptionService();
  }
})
</script>
<style lang="scss">
@import "../../scss/variables";
@import "./subscription";

@media (min-width: $screen-sm) {
  .subscription {
    @include subscription-mobile();

    .subscribe-button {
      bottom: 0;
      position: absolute;
    }

    .art {
      background-size: cover;
    }

    /*&.sub-row-green-colorful {
      @include subscription-desktop-row-color($green, $purple);
    }

    &.sub-row-violet-colorful {
      @include subscription-desktop-row-color($purple, $green);
    }

    &.sub-row-green {
      @include subscription-desktop-row($green);
    }

    &.sub-row-violet {
      @include subscription-desktop-row($purple);
    }

    &.sub-col-green {
      @include subscription-desktop-col($green);
    }

    &.sub-col-violet {
      @include subscription-desktop-col($purple);
    }*/
  }
}

@media (max-width: $screen-sm) {
  .subscription {
    @include subscription-mobile();
  }
}

.sub-card.desktop {
  cursor: pointer;
}

</style>